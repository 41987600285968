import request from '@/utils/request'

/**
 * 获取产品列表
 */
export function queryPage(data) {
	return request({
		url: 'front/productBasicWeb/queryPage',
		method: 'post',
		data,
	})
}

/**
 * 获取产品推荐、折扣、热销列表
 */
export function queryPageByDiscount(data) {
	return request({
		url: 'front/productBasicWeb/queryPageByDiscount',
		method: 'post',
		data,
	})
}

/**
 * 图搜
 */
export function searchPicture(data) {
	return request({
		url: 'front/productBasicWeb/SearchPicture',
		method: 'post',
		data,
	})
}

/**
 * 获取产品详情
 */
export function getDetail(data) {
	return request({
		url: 'front/productBasicWeb/getDetail',
		method: 'post',
		data,
	})
}

/**
 * 获取种类列表
 */
export function getClassList(data) {
	return request({
		url: 'front/productClassWeb/getTree',
		method: 'post',
		data,
	})
}

/**
 * 获取包装列表
 */
export function getPackList(data) {
	return request({
		url: 'front/productPackWeb/getList',
		method: 'post',
		data,
	})
}

/**
 * 获取包装列表
 */
export function getTagList(data) {
	return request({
		url: 'front/productBasicWeb/getTagList',
		method: 'post',
		data,
	})
}


/**
 * 获取相似样品资料列表
 */
export function getSameProductList(data) {
	return request({
		url: 'front/ProductBasicWeb/GetSameProductList',
		method: 'post',
		data,
	})
}

/**
 * 获取产品数量
 */
export function getProductCount(data) {
	return request({
		url: 'front/ProductBasicWeb/GetProductCount',
		method: 'post',
		data,
	})
}

/**
 * 获取最近有产品的天数
 */
 export function getDateList(data) {
	return request({
		url: 'front/ProductBasicWeb/GetDateList',
		method: 'post',
		data,
	})
}

/**
 * 获取最近有产品的月数
 */
 export function getMoothList(data) {
	return request({
		url: 'front/ProductBasicWeb/GetMoothList',
		method: 'post',
		data,
	})
}

/**
 * 获取业务推荐的产品列表
 */
 export function getSellRecPorductList(data) {
	return request({
		url: 'front/ProductBasicWeb/GetSellRecPorductList',
		method: 'post',
		data,
	})
}

/**
 * 随机获取样品列表
 */
 export function randomProducts(data) {
	return request({
		url: '/front/ProductBasicWeb/RandomProducts',
		method: 'post',
		data,
	})
}


