/*
 * @Description: 多明戈西班牙语
 * @Author: zml
 * @Date: 2023-09-14 18:01:55
 * @LastEditors: zml
 * @LastEditTime: 2023-09-27 11:49:30
 */

export default {
    "DMG.login":"Iniciar sesión",
    "DMG.register":"Registro",
    "DMG.logout":"Salida",
    "DMG.MyOrder":"Mi pedido",
    "DMG.logoBox.placeholder": "Por favor, introduzca la palabra clave del producto",
    "DMG.logoBox.search": "Buscar",
    "DMG.logoBox.AdvancedSearch": "Búsqueda avanzada",
    "DMG.nav.Home": "Casa",
    "DMG.nav.Products": "Productos",
    "DMG.nav.About": "Sobre nosotros",
    "DMG.nav.contact": "Contáctanos",
    "DMG.nav.news": "Noticias",
    "DMG.nav.cart": "Compras",
    "DMG.ViewPrice": "Ver el precio",
    "DMG.SameFactory": "La misma fábrica",
    "DMG.newPro": "Nuevos productos",
    "DMG.AnotherBatch": "Cambiar un lote",
    "DMG.ViewMore": "Ver más",
    "DMG.hotPro": "Productos de venta caliente",
    "DMG.hotVidio": "Video caliente",
    "DMG.proUnBoxVidio": "Video de apertura del producto",
    "DMG.Search": "Buscar",
    "DMG.Reset": "Restablecer",
    "DMG.Description": "Nombre del producto (cualquier parte del nombre del producto)",
    "DMG.ItemNO": "Número del proyecto",
    "DMG.MTCode": "Número de la empresa",
    "DMG.FTYCode": "Número de fábrica",
    "DMG.Kind": "Tipo",
    "DMG.Size": "Especificaciones",
    "DMG.Price": "Precio",
    "DMG.Date": "Fecha",
    "DMG.Sort": "Ordenar",
    "DMG.DateDesc": "Fecha Bajar",
    "DMG.DateAsc": "Fecha Subir",
    "DMG.PriceDesc": "Precio Bajar",
    "DMG.PriceAsc": "Precio Subir",
    "DMG.NO.Desc": "Número Bajar",
    "DMG.NO.Asc": "Número Subir",
    "DMG.PleaseEnterTheProductNameKeyword": "Introduzca la palabra clave del nombre del producto",
    "DMG.PleaseSelect": "Por favor, elija",
    "DMG.PleaseLength": "Largo",
    "DMG.PleaseWidth": "Ancho",
    "DMG.PleaseHeight": "Alto",
    "DMG.PleaseMinPrice": "Precio mínimo",
    "DMG.PleaseMaxPrice": "Precio máximo",
    "DMG.PleaseStartDate": "Fecha de inicio",
    "DMG.PleaseEndDate": "Fecha de finalización",
    "DMG.UserAccount": "Cuenta",
    "DMG.password": "Contraseña",
    "DMG.GROUPSEARCH": "Búsqueda integral",
    "DMG.Video_playback": "Reproducción de vídeo",
    "DMG.loginMsg": "Acceso a su cuenta y historial de pedidos",
    "DMG.onUserAccount": "No hay cuenta",
    "DMG.SignUp": "Registro",
    "DMG.ForgetPassword": "Olvida la contraseña",
    "DMG.resetPasswords": "Restablecer la contraseña",
    "DMG.emailAddress": "Buzón",
    "DMG.verifyCode": "Código",
    "DMG.getVerifyCode": "Acceso Código",
    "DMG.newPassword": "Nueva contraseña",
    "DMG.confirmPassword": "Confirmar contraseña",
    "DMG.Submit": "Presentación",
    "DMG.SignUpAccount": "Cuenta registrada",
    "DMG.LoginOnly": "Solo para iniciar sesión",
    "DMG.Name": "Nombre",
    "DMG.NameMsg": "Introduzca su nombre, de 2 a 30 caracteres",
    "DMG.passwordMsg": "Al menos 6 caracteres",
    "DMG.requiredFields": "*obligatorio",
    "DMG.Email": "Buzón",
    "DMG.registerEmail": "Buzón",
    "DMG.EmailMsg": "Por favor, introduzca el buzón. Para iniciar sesión.",
    "DMG.gender": "Género",
    "DMG.man": "Hombre",
    "DMG.woman": "Mujer",
    "DMG.customerSource": "Fuente del cliente",
    "DMG.salesman": "Vendedor",
    "DMG.Country": "País",
    "DMG.companyName": "Empresa",
    "DMG.duty": "Cargo",
    "DMG.tel": "Teléfono",
    "DMG.mobile": "Teléfono móvil",
    "DMG.fax": "Fax",
    "DMG.Yahoo": "Yahoo",
    "DMG.website": "Sitio web",
    "DMG.address": "Dirección",
    "DMG.postalCode": "Código postal",
    "DMG.ExistingAccount": "Cuenta existente",
    "DMG.DirectLogin": "Iniciar sesión directamente",
    "DMG.productPrice": "Precio del producto",
    "DMG.ProductNumber": "Número",
    "DMG.Packing": "Método de embalaje",
    "DMG.OuterBoxCBM": "Volumen de la caja exterior",
    "DMG.OuterBoxCUFT": "Volumen de la caja exterior",
    "DMG.Innerboxes": "Número de cajas interiores",
    "DMG.OuterQTY": "Volumen de la caja exterior",
    "DMG.GWAndNW": "Peso bruto / neto",
    "DMG.productSize": "Especificaciones del producto",
    "DMG.packageSize": "Especificaciones de embalaje",
    "DMG.cartonSize": "Especificaciones de la caja exterior",
    "DMG.addCart": "Añadir carrito de compras",
    "DMG.delCart": "Cancelar la compra adicional",
    "DMG.product": "Productos",
    "DMG.NewsUpdates": "Novedades periodísticas",
    "DMG.ReleaseDate":"Fecha de publicación",
    "DMG.MyCart":"Mi carrito de compras",
    "DMG.ID":"ID",
    "DMG.picture":"Imagen",
    "DMG.ArticleNo": "Número",
    "DMG.Description": "Nombre",
    "DMG.CartOuterQTY":"Fuera",
    "DMG.CBM":"CBM",
    "DMG.CTNS":"CTNS",
    "DMG.totalQTY": "Total",
    "DMG.TotalVolume":"Total CBM",
    "DMG.total":"Total",
    "DMG.delete":"Eliminar",
    "DMG.checkAll":"Selección completa",
    "DMG.clear":"Vaciado",
    "DMG.totalItems": "Número total",
    "DMG.totalCTNS": "Volumen total de cajas",
    "DMG.noData": "No hay datos por el momento",
    "DMG.totalPrice": "Precio total",
    "DMG.systemTip": "Consejos del sistema",
    "DMG.confirm": "Determinar",
    "DMG.cancel": "Cancelación",
    "DMG.deleteData": "¿¿ quieres eliminar este dato?",
    "DMG.clearCartData": "¿¿ vaciar todos los carritos de la compra?",
    "DMG.submitTip": "¿Confirmar la presentación del pedido?",
    "DMG.MyOrder": "Mi pedido",
    "DMG.PleaseOrderNum": "Por favor, introduzca el número de pedido.",
    "DMG.number": "Número único",
    "DMG.viewDetails": "Ver detalles",
    "DMG.orderTime": "Tiempo de pedido",
    "DMG.linkman": "Contactos",
    "DMG.sendDate": "Tiempo de envío",
    "DMG.expressNumber": "Número de la lista de mensajería",
    "DMG.orderStatus": "Estado del pedido",
    "DMG.unshipped": "No enviado",
    "DMG.shipped": "Enviado",
    "DMG.SiteNavigation": "Navegación del sitio web",
    "DMG.ContactUs": "Contáctanos",
    "DMG.FollowUs": "Preste atención a nosotros",
    "DMG.Landline": "Avión fijo",
    "DMG.contactEmail": "Correo electrónico de contacto",
    "DMG.Phone": "Móvil",
    "DMG.WeChat": "WeChat",
    "DMG.Technic": "Soporte técnico",
    "DMG.Software": "HongSheng Soft",
    "DMG.detailBtn": "Detalles del producto",
    "DMG.noDetailBtn": "No Detalles del producto",
    "DMG.classify": "Clasificación",
    "DMG.exclusive": "Recomendaciones exclusivas para",
    "DMG.loading": "Cargado...",
    "DMG.updateTime": "Tiempo",
    "DMG.SearchMsg": "Los criterios de búsqueda anteriores son opcionales para cualquiera o más búsquedas integrales.",
    "DMG.MessageTitle": "Enviar sus necesidades en línea",
    "DMG.MessageMsg": "Responderemos a su correo electrónico en 24 horas, excepto en días festivos.",
    "DMG.MessageName": "Tu nombre",
    "DMG.MessageTel": "Tu teléfono móvil",
    "DMG.MessageEmail": "Tu buzón",
    "DMG.MessageNeeds": "Tus necesidades",
    "DMG.SendInquiry": "Investigación",
    "DMG.EnquiryList": "Investigación",
    "DMG.enquiry": "Investigación",
    "DMG.MyEnquiryList": "Mi formulario de consulta",
    "DMG.Remark": "Nota",
    "DMG.Enquirys": "Deje su correo electrónico para facilitar el contacto.",
    "DMG.proClass": "Clasificación de productos",
    "DMG.title": "Welcome to the official website of Xiamen Domingo Trading Co., Ltd.!",
    "DMG.userAccount":"Nombre usuario",
    "DMG.CompanyProfile":"Perfil de la empresa",
    "DMG.Exhibition":"Exposición",
    "DMG.More":"More",
    "DMG.Hotsales":"Productos de venta caliente",
    "DMG.Comprehensivequery":"Consulta integral",
    "DMG.Productrype": "Tipo",
    "DMG.Producprice": "Precio",
    "DMG.sortorder": "Ordenar",
    "DMG.advancedsearch":"Búsqueda avanzada",
    "DMG.Factorymodel":"Modelo de fábrica",
    "DMG.Productsize":"Tamaño del producto",
    "DMG.SerialNumber":"Número de serie",
    "DMG.identifier":"Número",
    "DMG.volume":"Volumen",
    "DMG.package":"Embalaje",
    "DMG.Directlogin":"Iniciar sesión directamente",
    "DMG.Sendverificationcode":"Enviar Código de verificación",
    "DMG.confirmnewPassword": "Confirmar contraseña",
    "DMG.PackageNo":"Número de embalaje",
    "DMG.loginSuccess":"Inicio de sesión exitoso",
    "DMG.regSuccess":"Inicio de sesión exitoso",
    "DMG.resetsuccessful":"Reinicio exitoso",
    "DMG.ProName":"Nombre",
    "DMG.Disclaimer": "Descargo de responsabilidad: todas las imágenes y productos de este sitio web provienen del fabricante, y los derechos de autor pertenecen al fabricante.",
}   
