/*
 * @Description: 多明戈英文
 * @Author: zml
 * @Date: 2023-09-14 18:01:55
 * @LastEditors: zml
 * @LastEditTime: 2023-09-27 12:08:10
 */

export default {
    "DMG.login":"Login",
    "DMG.register":"Register",
    "DMG.logout":"Logout",
    "DMG.MyOrder":"My Order",
    "DMG.logoBox.placeholder": "Please enter the product keyword",
    "DMG.logoBox.search": "Search",
    "DMG.logoBox.AdvancedSearch": "Advanced Search",
    "DMG.nav.Home": "Home",
    "DMG.nav.Products": "Products",
    "DMG.nav.About": "About Us",
    "DMG.nav.contact": "Contact Us",
    "DMG.nav.news": "NEWS",
    "DMG.nav.cart": "Cart",
    "DMG.ViewPrice": "View Price",
    "DMG.SameFactory": "Same Factory",
    "DMG.newPro": "NEW ARRIVIALS",
    "DMG.AnotherBatch": "Another Batch",
    "DMG.ViewMore": "View More",
    "DMG.hotPro": "HOT ITEMS",
    "DMG.hotVidio": "HOT ITEM VODEO",
    "DMG.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "DMG.Search": "SEARCH",
    "DMG.Reset": "RESET",
    "DMG.Description": "Description (Any Part Of Product Name)",
    "DMG.ItemNO": "Item NO.",
    "DMG.MTCode": "MT Code",
    "DMG.FTYCode": "FTY Code",
    "DMG.Kind": "Kind",
    "DMG.Size": "Size",
    "DMG.Price": "Price",
    "DMG.Date": "Date",
    "DMG.Sort": "Sort",
    "DMG.DateDesc": "Date DESC",
    "DMG.DateAsc": "Date ASC",
    "DMG.PriceDesc": "Price DESC",
    "DMG.PriceAsc": "Price ASC",
    "DMG.NO.Desc": "NO. DESC",
    "DMG.NO.Asc": "NO. ASC",
    "DMG.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "DMG.PleaseSelect": "Please select",
    "DMG.PleaseLength": "Length",
    "DMG.PleaseWidth": "Width",
    "DMG.PleaseHeight": "Height",
    "DMG.PleaseMinPrice": "Min price",
    "DMG.PleaseMaxPrice": "Max price",
    "DMG.PleaseStartDate": "Start date",
    "DMG.PleaseEndDate": "End date",
    "DMG.UserAccount": "User Account",
    "DMG.password": "Password",
    "DMG.GROUPSEARCH": "GROUPSEARCH",
    "DMG.Video_playback": "Video playing",
    "DMG.loginMsg": "Access your account and order history",
    "DMG.onUserAccount": "No account",
    "DMG.SignUp": "Sign up",
    "DMG.ForgetPassword": "Forget password",
    "DMG.resetPasswords": "Reset password",
    "DMG.emailAddress": "Contact Email",
    "DMG.verifyCode": "Verify code",
    "DMG.getVerifyCode": "Get code",
    "DMG.newPassword": "New password",
    "DMG.confirmPassword": "Confirm password",
    "DMG.Submit": "Submit",
    "DMG.SignUpAccount": "Sign up",
    "DMG.LoginOnly": "Login only",
    "DMG.Name": "Name",
    "DMG.NameMsg": "Please enter your name, 2 to 30 characters",
    "DMG.passwordMsg": "At least 6 characters",
    "DMG.requiredFields": "*Required fields",
    "DMG.registerEmail": "E-mail",
    "DMG.EmailMsg": "Please enter email. Used for login",
    "DMG.gender": "Gender",
    "DMG.man": "Man",
    "DMG.woman": "Woman",
    "DMG.customerSource": "Customer source",
    "DMG.salesman": "Contact Salesman",
    "DMG.Country": "Country",
    "DMG.companyName": "Company name",
    "DMG.duty": "Job Titel",
    "DMG.tel": "Tel",
    "DMG.mobile": "Mobile",
    "DMG.fax": "Fax",
    "DMG.Yahoo": "Yahoo",
    "DMG.website": "Website",
    "DMG.address": "Address",
    "DMG.postalCode": "Postal code",
    "DMG.ExistingAccount": "Existing account",
    "DMG.DirectLogin": "Direct login",
    "DMG.productPrice": "Price",
    "DMG.ProductNumber": "Item NO.",
    "DMG.Packing": "Packing",
    "DMG.OuterBoxCBM": "CBM",
    "DMG.OuterBoxCUFT": "CUFT",
    "DMG.Innerboxes": "Inner Boxes",
    "DMG.OuterQTY": "Outer QTY",
    "DMG.GWAndNW": "G.W/N.W",
    "DMG.productSize": "Product Size",
    "DMG.packageSize": "Package Size",
    "DMG.cartonSize": "Carton Size",
    "DMG.addCart": "Add To Cart",
    "DMG.delCart": "Cancel Purchase",
    "DMG.product": "PRODUCTS",
    "DMG.NewsUpdates": "News Updates",
    "DMG.ReleaseDate":"Release Date",
    "DMG.MyCart":"My Cart",
    "DMG.ID":"ID",
    "DMG.picture":"Picture",
    "DMG.ArticleNo":"Item No.",
    "DMG.Description":"Description",
    "DMG.CartOuterQTY":"Outer QTY",
    "DMG.CBM":"CBM",
    "DMG.CTNS":"CTNS",
    "DMG.totalQTY": "Total QTY",
    "DMG.TotalVolume":"Total CBM",
    "DMG.total":"Total",
    "DMG.delete":"Delete",
    "DMG.checkAll":"Check All",
    "DMG.clear":"Clear",
    "DMG.totalItems": "Total Items",
    "DMG.totalCTNS": "Total CTNS",
    "DMG.noData": "No Data",
    "DMG.totalPrice": "Total Price",
    "DMG.systemTip": "Tip",
    "DMG.confirm": "Confirm",
    "DMG.cancel": "Cancel",
    "DMG.deleteData": "Do you want to delete the data?",
    "DMG.clearCartData": "Do you want to empty your shopping cart?",
    "DMG.submitTip": "Confirm to submit the order?",
    "DMG.MyOrder": "My Order",
    "DMG.PleaseOrderNum": "Please enter the order number",
    "DMG.number": "Number",
    "DMG.viewDetails": "View Details",
    "DMG.orderTime": "Order Time",
    "DMG.linkman": "Linkman",
    "DMG.sendDate": "Delivery Date",
    "DMG.expressNumber": "Tracking Number",
    "DMG.orderStatus": "Order Status",
    "DMG.unshipped": "Unshipped",
    "DMG.shipped": "Shipped",
    "DMG.SiteNavigation": "Site Navigation",
    "DMG.ContactUs": "Contact us",
    "DMG.FollowUs": "Follow us",
    "DMG.Landline": "Landline",
    "DMG.Email": "E-mail",
    "DMG.Phone": "Phone",
    "DMG.WeChat": "WeChat",
    "DMG.Technic": "Technic Sustain",
    "DMG.Software": "HongSheng Soft",
    "DMG.detailBtn": "Product details",
    "DMG.noDetailBtn": "No product details",
    "DMG.classify": "Classify",
    "DMG.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "DMG.loading": "Loading...",
    "DMG.updateTime": "UpdateTime",
    "DMG.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "DMG.MessageTitle": "Submit your requirements online",
    "DMG.MessageMsg": "We will reply to your email within 24 hours, excluding holidays",
    "DMG.MessageName": "YOUR NAME",
    "DMG.MessageTel": "YOUR PHONE",
    "DMG.MessageEmail": "YOUR EMAIL",
    "DMG.MessageNeeds": "YOUR NEEDS",
    "DMG.SendInquiry": "Send Inquiry",
    "DMG.EnquiryList": "ENQUIRY",
    "DMG.enquiry": "Enquiry",
    "DMG.MyEnquiryList": "My Inquiry Form",
    "DMG.Remark": "Remark",
    "DMG.Enquirys": "Leave your email and we'll get back to you",
    "DMG.proClass": "Product Category",
    "DMG.title": "¡¡ Bienvenidos al sitio web oficial de Xiamen domingo Trading co., ltd.!",
    "DMG.userAccount":"User Name",
    "DMG.CompanyProfile":"Company Profile",
    "DMG.Exhibition":"Exhibition",
    "DMG.More":"More",
    "DMG.Hotsales":"H+ot products",
    "DMG.Comprehensivequery":"Comprehensive query",
    "DMG.Productrype": "Category",
    "DMG.Producprice": "Price",
    "DMG.sortorder": "Sort",
    "DMG.similarproduct":"Similar product",
    "DMG.advancedsearch":"Advanced search",
    "DMG.Factorymodel":"Factory model",
    "DMG.Productsize":"Product size",
    "DMG.SerialNumber":"Serial Number",
    "DMG.identifier":"Number",
    "DMG.volume":"volume",
    "DMG.package":"Package",
    "DMG.Directlogin":"Direct login",
    "DMG.Sendverificationcode":"Send verification code",
    "DMG.confirmnewPassword": "Confirm new password",
    "DMG.PackageNo":"Package No",
    "DMG.loginSuccess":"Login succeeded",
    "DMG.regSuccess":"Login was successful",
    "DMG.resetsuccessful":"Reset successful",
    "DMG.ProName":"Product name",
    "DMG.Disclaimer": "Disclaimer: All images and products on this website are from the manufacturer, and the copyright belongs to the manufacturer.",
}   
