/*
 * @Description: 国安英文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-22 16:45:30
 */
export default {
    "GA.login":"Login",
    "GA.register":"Register",
    "GA.logout":"Logout",
    "GA.MyOrder":"My Order",
    "GA.logoBox.placeholder": "Please enter the product keyword",
    "GA.logoBox.search": "Search",
    "GA.logoBox.AdvancedSearch": "Advanced Search",
    "GA.nav.Home": "HOME",
    "GA.nav.Products": "PRODUCTS",
    "GA.nav.About": "ABOUT US",
    "GA.nav.contact": "CONTACT US",
    "GA.nav.news": "NEWS",
    "GA.nav.cart": "CART",
    "GA.ViewPrice": "View Price",
    "GA.SameFactory": "Same Factory",
    "GA.newPro": "NEW ARRIVIALS",
    "GA.AnotherBatch": "Another Batch",
    "GA.ViewMore": "View More",
    "GA.hotPro": "HOT ITEMS",
    "GA.hotVidio": "HOT ITEM VODEO",
    "GA.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "GA.Search": "SEARCH",
    "GA.Reset": "RESET",
    "GA.ItemNO": "Item NO.",
    "GA.MTCode": "MT Code",
    "GA.FTYCode": "FTY Code",
    "GA.Kind": "Kind",
    "GA.Size": "Size",
    "GA.Price": "Price",
    "GA.Date": "Date",
    "GA.Sort": "Sort",
    "GA.DateDesc": "DATE DESC",
    "GA.DateAsc": "DATE ASC",
    "GA.PriceDesc": "PRICE DESC",
    "GA.PriceAsc": "PRICE ASC",
    "GA.NO.Desc": "NO. DESC",
    "GA.NO.Asc": "NO. ASC",
    "GA.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "GA.PleaseSelect": "Please select",
    "GA.PleaseLength": "Length",
    "GA.PleaseWidth": "Width",
    "GA.PleaseHeight": "Height",
    "GA.PleaseMinPrice": "Min price",
    "GA.PleaseMaxPrice": "Max price",
    "GA.PleaseStartDate": "Start date",
    "GA.PleaseEndDate": "End date",
    "GA.UserAccount": "User Account",
    "GA.password": "Password",
    "GA.GROUPSEARCH": "GROUPSEARCH",
    "GA.Video_playback": "Video playing",
    "GA.loginMsg": "Access your account and order history",
    "GA.onUserAccount": "No account",
    "GA.SignUp": "Sign up",
    "GA.ForgetPassword": "Forget password",
    "GA.resetPasswords": "Reset password",
    "GA.emailAddress": "Email address",
    "GA.verifyCode": "Verify code",
    "GA.getVerifyCode": "Get code",
    "GA.newPassword": "New password",
    "GA.confirmPassword": "Confirm password",
    "GA.Submit": "Submit",
    "GA.SignUpAccount": "Sign up",
    "GA.LoginOnly": "Login only",
    "GA.Name": "Name",
    "GA.NameMsg": "Please enter your name, 2 to 30 characters",
    "GA.passwordMsg": "At least 6 characters",
    "GA.requiredFields": "*Required fields",
    "GA.Email": "Email",
    "GA.EmailMsg": "Please enter email. Used for login",
    "GA.gender": "Gender",
    "GA.man": "Mr.",
    "GA.woman": "Ms.",
    "GA.customerSource": "Customer source",
    "GA.salesman": "Contact Salesman",
    "GA.Country": "Country",
    "GA.companyName": "Company name",
    "GA.duty": "Job Titel",
    "GA.tel": "Tel",
    "GA.mobile": "Mobile",
    "GA.fax": "Fax",
    "GA.Yahoo": "Yahoo",
    "GA.website": "Website",
    "GA.address": "Address",
    "GA.postalCode": "Postal code",
    "GA.ExistingAccount": "Existing account",
    "GA.DirectLogin": "Direct login",
    "GA.productPrice": "Price",
    "GA.ProductNumber": "Item NO.",
    "GA.Packing": "Packing",
    "GA.OuterBoxCBM": "CBM",
    "GA.OuterBoxCUFT": "CUFT",
    "GA.Innerboxes": "Inner Boxes",
    "GA.OuterQTY": "Outer QTY",
    "GA.GWAndNW": "G.W/N.W",
    "GA.productSize": "Product Size",
    "GA.packageSize": "Package Size",
    "GA.cartonSize": "Carton Size",
    "GA.addCart": "Add To Cart",
    "GA.delCart": "Remove from Cart",
    "GA.product": "PRODUCTS",
    "GA.NewsUpdates": "News Updates",
    "GA.ReleaseDate":"Release Date",
    "GA.MyCart":"My Cart",
    "GA.ID":"ID",
    "GA.picture":"Picture",
    "GA.ArticleNo":"Item No.",
    "GA.Description":"Product Name",
    "GA.CartOuterQTY":"Outer QTY",
    "GA.CBM":"CBM",
    "GA.CTNS":"CTNS",
    "GA.totalQTY": "Total QTY",
    "GA.TotalVolume":"Total CBM",
    "GA.total":"Total",
    "GA.delete":"Delete",
    "GA.checkAll":"Check All",
    "GA.clear":"Clear",
    "GA.totalItems": "Total Items",
    "GA.totalCTNS": "Total CTNS",
    "GA.noData": "No Data",
    "GA.totalPrice": "Total Price",
    "GA.systemTip": "Tip",
    "GA.confirm": "Confirm",
    "GA.cancel": "Cancel",
    "GA.deleteData": "Do you want to delete the data?",
    "GA.clearCartData": "Do you want to empty your shopping cart?",
    "GA.submitTip": "Confirm to submit the order?",
    "GA.MyOrder": "My Order",
    "GA.PleaseOrderNum": "Please enter the order number",
    "GA.number": "Number",
    "GA.viewDetails": "View Details",
    "GA.orderTime": "Creation time",
    "GA.linkman": "Linkman",
    "GA.sendDate": "Delivery Date",
    "GA.expressNumber": "Tracking Number",
    "GA.orderStatus": "Order Status",
    "GA.unshipped": "Unshipped",
    "GA.shipped": "Shipped",
    "GA.SiteNavigation": "Site Navigation",
    "GA.ContactUs": "Contact us",
    "GA.FollowUs": "Follow us",
    "GA.Landline": "Landline",
    "GA.Email": "E-mail",
    "GA.Phone": "Phone",
    "GA.WeChat": "WeChat",
    "GA.Technic": "Technic Sustain",
    "GA.Software": "HongSheng Soft",
    "GA.detailBtn": "Product details",
    "GA.noDetailBtn": "No product details",
    "GA.classify": "CATALOG",
    "GA.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "GA.loading": "Loading...",
    "GA.updateTime": "UpdateTime",
    "GA.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "GA.MessageTitle": "Submit your requirements online",
    "GA.MessageMsg": "We will reply to your email within 24 hours, excluding holidays",
    "GA.MessageName": "YOUR NAME",
    "GA.MessageTel": "YOUR PHONE",
    "GA.MessageEmail": "YOUR EMAIL",
    "GA.MessageNeeds": "YOUR NEEDS",
    "GA.SendInquiry": "Send Inquiry",
    "GA.EnquiryList": "ENQUIRY",
    "GA.enquiry": "Enquiry",
    "GA.MyEnquiryList": "My Inquiry Form",
    "GA.Remark": "Remark",
    "GA.Enquirys": "Leave your email and we'll get back to you",
    "GA.proClass": "Product Category",
    "GA.submitOrder": "Confirm submitting the inquiry?",
}   
