/*
 * @Description: 凯荣丰英文
 * @Author: zml
 * @Date: 2023-04-17 11:52:16
 * @LastEditors: zml
 * @LastEditTime: 2023-06-15 09:41:10
 */

export default {
    "KRF.navTitle.Home":"Home",
    "KRF.navTitle.Produch":"Product",
    "KRF.navTitle.Cart":"Cart",
    "KRF.navTitle.Exhibition":"Exhibition Plan",
    "KRF.navTitle.Message":"Contact / About Us",
    "KRF.logoBox.placeholder":"Please enter the product keyword",
    "KRF.ProductNumber":"Item NO.",
    "KRF.amount":"Quantity",
    "KRF.contactUs":"Contact Us",
    "KRF.understandDeke":"About Us",
    "KRF.telephone":"Telephone",
    "KRF.phoneAndWeixin":"Phone & Wechat",
    "KRF.PleaseOrderNum":"Please enter the order number",
    "KRF.ArticleNo":"Item No.",
    "KRF.TotalVolume":"Total CBM",
    "KRF.totalQTY": "Total QTY",
    "KRF.totalItems": "Total Items",
    "KRF.ExhibitionRelatedProducts":"Exhibition related products",
    "KRF.ProductsVideo":"Products Video",
    "KRF.ReleaseDate":"Release Date",
    "KRF.inquirySheet":"Inquiry List",
    "KRF.SendInquiry":"Add to inquiry",
    "KRF.productPrice":"Price",
    "KRF.OuterBoxCBM":"CBM",
    "KRF.OuterBoxCUFT":"CUFT",
    "KRF.Innerboxes":"Inner Boxes",
    "KRF.OuterCapacity":"Outer QTY",
    "KRF.productDetails":"Product details",
    "KRF.similarProduct":"Similar product",
    "KRF.MyRFQ":"My RFQ",
    "KRF.enquiry":"Enquiry",
    "KRF.enquirys": "Leave your email and we'll get back to you",
    "KRF.contact":"Contact Person",
    "KRF.Description":"Description",
    "KRF.OuterQTY":"Outer QTY",
    "KRF.LastSevenDays": "Seven Days",
    "KRF.All": "All",
    "KRF.PleaseEmial": "Please enter your email",
    "KRF.PleaseCode": "Please enter the vailde code",
    "KRF.PleasePhone": "Please enter your mobile phone number",
    "KRF.PleaseName": "Please enter your name",
    "KRF.userName": "Username",
    "KRF.PleaseAddress": "Please enter your contact address",

    "KRF.loginTitle": "Log in with an account",
    "KRF.login": "Login",
    "KRF.PleaseAccount": "Please enter the username or email",
    "KRF.PleasesAccount": "Please enter your username",
    "KRF.passwordEmpty": "Password cannot be empty",
    "KRF.Password": "Password",
    "KRF.PleasePassword": "Please enter your password",
    "KRF.news": "News",
    "KRF.Video": "Video",
    "KRF.Search": "Search",
    "KRF.AdvancedSearch": "Advanced Search",
    "KRF.close": "Close",
    "KRF.open": "Open",
    "KRF.Emial": "Emial",
    "KRF.Tel": "Tel",
    "KRF.WeChat": "WeChat",
    "KRF.updateData": "Update Data",
    "KRF.confirm": "Confirm",
    "KRF.updateSuccessfully": "Update Successfully",
    "KRF.UpdateFailure": "Update Failure",
    "KRF.Logout": "Logout",
    "KRF.MyOrders": "My Order",
    "KRF.Cart": "Cart",
    "KRF.Hello": "Hello",
    "KRF.hotProduct": "Hot Products",
    "KRF.Country": "Country",
    "KRF.PleaseCountry": "Please enter your country",
    "KRF.BrandZone": "Brand Zone",
    "KRF.ViewMore": "View More",
}   
