/*
 * @Description: 国安西班牙语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-22 12:16:02
 */
export default {
    "GA.login":"Iniciar sesión",
    "GA.register":"Registro",
    "GA.logout":"Salida",
    "GA.MyOrder":"Mi pedido",
    "GA.logoBox.placeholder": "Por favor, introduzca la palabra clave del producto",
    "GA.logoBox.search": "Buscar",
    "GA.logoBox.AdvancedSearch": "Búsqueda avanzada",
    "GA.nav.Home": "página de inicio",
    "GA.nav.Products": "Productos",
    "GA.nav.About": "Sobre nosotros",
    "GA.nav.contact": "Contáctanos",
    "GA.nav.news": "Noticias",
    "GA.nav.cart": "Carrito de compras",
    "GA.ViewPrice": "Ver el precio",
    "GA.SameFactory": "La misma fábrica",
    "GA.newPro": "Nuevos productos",
    "GA.AnotherBatch": "Cambiar un lote",
    "GA.ViewMore": "Ver más",
    "GA.hotPro": "Productos de venta caliente",
    "GA.hotVidio": "Video caliente",
    "GA.proUnBoxVidio": "Video de apertura del producto",
    "GA.Search": "Buscar",
    "GA.Reset": "Restablecer",
    "GA.ItemNO": "Número del proyecto",
    "GA.MTCode": "Número de la empresa",
    "GA.FTYCode": "Número de fábrica",
    "GA.Kind": "Tipo",
    "GA.Size": "Especificaciones",
    "GA.Price": "Precio",
    "GA.Date": "Fecha",
    "GA.Sort": "Ordenar",
    "GA.DateDesc": "Fecha Bajar",
    "GA.DateAsc": "Fecha Subir",
    "GA.PriceDesc": "Precio Bajar",
    "GA.PriceAsc": "Precio Subir",
    "GA.NO.Desc": "Número Bajar",
    "GA.NO.Asc": "Número Subir",
    "GA.PleaseEnterTheProductNameKeyword": "Introduzca la palabra clave del nombre del producto",
    "GA.PleaseSelect": "Por favor, elija",
    "GA.PleaseLength": "Largo",
    "GA.PleaseWidth": "Ancho",
    "GA.PleaseHeight": "Alto",
    "GA.PleaseMinPrice": "Precio mínimo",
    "GA.PleaseMaxPrice": "Precio máximo",
    "GA.PleaseStartDate": "Fecha de inicio",
    "GA.PleaseEndDate": "Fecha de finalización",
    "GA.UserAccount": "Cuenta",
    "GA.password": "Contraseña",
    "GA.GROUPSEARCH": "Búsqueda integral",
    "GA.Video_playback": "Reproducción de vídeo",
    "GA.loginMsg": "Acceso a su cuenta y historial de pedidos",
    "GA.onUserAccount": "No hay cuenta",
    "GA.SignUp": "Registro",
    "GA.ForgetPassword": "Olvida la contraseña",
    "GA.resetPasswords": "Restablecer la contraseña",
    "GA.emailAddress": "Buzón",
    "GA.verifyCode": "Código",
    "GA.getVerifyCode": "Acceso Código",
    "GA.newPassword": "Nueva contraseña",
    "GA.confirmPassword": "Confirmar contraseña",
    "GA.Submit": "Presentación",
    "GA.SignUpAccount": "Cuenta registrada",
    "GA.LoginOnly": "Solo para iniciar sesión",
    "GA.Name": "Nombre",
    "GA.NameMsg": "Introduzca su nombre, de 2 a 30 caracteres",
    "GA.passwordMsg": "Al menos 6 caracteres",
    "GA.requiredFields": "*obligatorio",
    "GA.Email": "Buzón",
    "GA.EmailMsg": "Por favor, introduzca el buzón. Para iniciar sesión.",
    "GA.gender": "Género",
    "GA.man": "Hombre",
    "GA.woman": "Señora",
    "GA.customerSource": "Fuente del cliente",
    "GA.salesman": "Vendedor",
    "GA.Country": "País",
    "GA.companyName": "Empresa",
    "GA.duty": "Cargo",
    "GA.tel": "Teléfono",
    "GA.mobile": "Teléfono móvil",
    "GA.fax": "Fax",
    "GA.Yahoo": "Yahoo",
    "GA.website": "Sitio web",
    "GA.address": "Dirección",
    "GA.postalCode": "Código postal",
    "GA.ExistingAccount": "Cuenta existente",
    "GA.DirectLogin": "Iniciar sesión directamente",
    "GA.productPrice": "Precio del producto",
    "GA.ProductNumber": "Número de producto",
    "GA.Packing": "Método de embalaje",
    "GA.OuterBoxCBM": "Volumen de la caja exterior",
    "GA.OuterBoxCUFT": "Volumen de la caja exterior",
    "GA.Innerboxes": "Número de cajas interiores",
    "GA.OuterQTY": "Volumen de la caja exterior",
    "GA.GWAndNW": "Peso bruto / neto",
    "GA.productSize": "Especificaciones del producto",
    "GA.packageSize": "Especificaciones de embalaje",
    "GA.cartonSize": "Especificaciones de la caja exterior",
    "GA.addCart": "Añadir carrito de compras",
    "GA.delCart": "Fuera del carrito de la compra",
    "GA.product": "Productos",
    "GA.NewsUpdates": "Novedades periodísticas",
    "GA.ReleaseDate":"Fecha de publicación",
    "GA.MyCart":"Mi carrito de compras",
    "GA.ID":"ID",
    "GA.picture":"Imagen",
    "GA.ArticleNo": "Número",
    "GA.Description": "Nombre del producto",
    "GA.CartOuterQTY":"Fuera",
    "GA.CBM":"CBM",
    "GA.CTNS":"CTNS",
    "GA.totalQTY": "Total",
    "GA.TotalVolume":"Total CBM",
    "GA.total":"Total",
    "GA.delete":"Eliminar",
    "GA.checkAll":"Selección completa",
    "GA.clear":"Vaciado",
    "GA.totalItems": "Número total",
    "GA.totalCTNS": "Volumen total de cajas",
    "GA.noData": "No hay datos por el momento",
    "GA.totalPrice": "Precio total",
    "GA.systemTip": "Consejos del sistema",
    "GA.confirm": "Determinar",
    "GA.cancel": "Cancelación",
    "GA.deleteData": "¿¿ quieres eliminar este dato?",
    "GA.clearCartData": "¿¿ vaciar todos los carritos de la compra?",
    "GA.submitTip": "¿Confirmar la presentación del pedido?",
    "GA.MyOrder": "Mi pedido",
    "GA.PleaseOrderNum": "Por favor, introduzca el número de pedido.",
    "GA.number": "Número único",
    "GA.viewDetails": "Ver detalles",
    "GA.orderTime": "Tiempo de creación",
    "GA.linkman": "Contactos",
    "GA.sendDate": "Tiempo de envío",
    "GA.expressNumber": "Número de la lista de mensajería",
    "GA.orderStatus": "Estado del pedido",
    "GA.unshipped": "No enviado",
    "GA.shipped": "Enviado",
    "GA.SiteNavigation": "Navegación del sitio web",
    "GA.ContactUs": "Contáctanos",
    "GA.FollowUs": "Preste atención a nosotros",
    "GA.Landline": "Avión fijo",
    "GA.Email": "Buzón",
    "GA.Phone": "Móvil",
    "GA.WeChat": "WeChat",
    "GA.Technic": "Soporte técnico",
    "GA.Software": "HongSheng Soft",
    "GA.detailBtn": "Detalles del producto",
    "GA.noDetailBtn": "No Detalles del producto",
    "GA.classify": "Clasificación",
    "GA.exclusive": "Recomendaciones exclusivas para",
    "GA.loading": "Cargado...",
    "GA.updateTime": "Tiempo",
    "GA.SearchMsg": "Los criterios de búsqueda anteriores son opcionales para cualquiera o más búsquedas integrales.",
    "GA.MessageTitle": "Enviar sus necesidades en línea",
    "GA.MessageMsg": "Responderemos a su correo electrónico en 24 horas, excepto en días festivos.",
    "GA.MessageName": "Tu nombre",
    "GA.MessageTel": "Tu teléfono móvil",
    "GA.MessageEmail": "Tu buzón",
    "GA.MessageNeeds": "Tus necesidades",
    "GA.SendInquiry": "Investigación",
    "GA.EnquiryList": "Investigación",
    "GA.enquiry": "Investigación",
    "GA.MyEnquiryList": "Mi formulario de consulta",
    "GA.Remark": "Nota",
    "GA.Enquirys": "Deje su correo electrónico para facilitar el contacto.",
    "GA.proClass": "Clasificación de productos",
    "GA.submitOrder": "¿Confirmar la presentación de la consulta?",
}   
