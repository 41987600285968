/*
 * @Description: 锦泰国际中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-06-23 10:08:32
 */
export default {
    "JT.navTitle.Home": "首页",
    "JT.navTitle.Produch": "产品展示",
    "JT.navTitle.Cart": "购物车",
    "JT.navTitle.Exhibition": "展会计划",
    "JT.navTitle.Message": "联系 / 关于我们",
    "JT.logoBox.placeholder": "请输入产品关键字",
    "JT.ProductNumber": "产品编号",
    "JT.amount": "数量",
    "JT.contactUs": "联系我们",
    "JT.understandDeke": "了解德科",
    "JT.telephone": "座机",
    "JT.Tel": "手机",
    "JT.phoneAndWeixin": "手机 & 微信",
    "JT.PleaseOrderNum": "请输入订单号",
    "JT.ArticleNo": "货号",
    "JT.TotalVolume": "总体积",
    "JT.totalQTY": "总数量",
    "JT.totalItems": "总款数",
    "JT.ExhibitionRelatedProducts": "展会关联产品",
    "JT.ProductsVideo": "产品视频",
    "JT.ReleaseDate": "发布日期",
    "JT.inquirySheet": "询价单",
    "JT.SendInquiry": "发询价单",
    "JT.productPrice": "产品价格",
    "JT.OuterBoxCBM": "外箱体积",
    "JT.OuterBoxCUFT": "外箱材积",
    "JT.Innerboxes": "内盒个数",
    "JT.OuterCapacity": "外箱装量",
    "JT.productDetails": "产品详情",
    "JT.similarProduct": "相似产品",
    "JT.MyRFQ": "我的询价单",
    "JT.enquiry": "询价",
    "JT.enquirys": "请留下您的电子邮件，我们会与您联系",
    "JT.contact": "联系方式",
    "JT.contact": "联系方式",
    "JT.Description": "名称",
    "JT.OuterQTY": "外箱装量",
    "JT.LastSevenDays": "最近七天",
    "JT.All": "全部",
    "JT.PleaseEmial": "请输入邮箱",
    "JT.PleaseCode": "请输入验证码",
    "JT.PleasePhone": "请输入手机号",
    "JT.PleaseAccount": "请输入用户名",
    "JT.userName": "用户名",
    "JT.PleaseAddress": "请输入联系地址",
    "JT.PleasePassword": "请输入密码",
    "JT.PleaseName": "请输入姓名",
    "JT.CompanyProfile": "公司简介",
    "JT.ContactPerson": "联系人",
    "JT.Emial": "邮箱",
    "JT.enterSearch": "请输入关键字",
    "JT.CompanyDescription": "21年专注儿童玩具企业",
    "JT.viewMore":"查看更多",
    "JT.Cart": "购物车",
    "JT.Service": "客服",
    "JT.WeChat": "微信",
    "JT.Top": "置顶",
    "JT.LastSevenDays": "最近七天",
}
