<!--
 * @Description: 关于我们
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: xie
 * @LastEditTime: 2024-07-31 14:59:25
-->
<template>
  <div>
    <component :datas="aboutData" :is="componentName"></component>
  </div>
</template>
<script>
import { getCompanyInfo } from '@/api/home'
import mixins from '@/utils/mixins'
export default {
  name: 'aboutPage',
  mixins: [mixins],
  data() {
    return {
      aboutData: {},
      componentName: ''
    }
  },
  created() {
    this.init()
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'aboutDetailOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'aboutDetailTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'aboutDetailThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'aboutDetailFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'aboutDetailFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'aboutDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'aboutDetailSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'aboutDetailEight'
          break
        case 'themeTemplateNine':
          this.componentName = 'aboutDetailNine'
          break
        case 'themeTemplateTen':
          this.componentName = 'aboutDetailTen'
          break
        default:
          this.componentName = ''
          break
      }
    },
    init() {
      // 获取公司简介
      getCompanyInfo().then(res => {
        if (res.success) {
          this.aboutData = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>