/*
 * @Description: 凯荣丰中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-05-31 14:39:33
 */
export default {
    "KRF.navTitle.Home": "首页",
    "KRF.navTitle.Produch": "产品展示",
    "KRF.navTitle.Cart": "购物车",
    "KRF.navTitle.Exhibition": "展会计划",
    "KRF.navTitle.Message": "联系 / 关于我们",
    "KRF.logoBox.placeholder": "请输入产品关键字",
    "KRF.ProductNumber": "产品编号",
    "KRF.amount": "数量",
    "KRF.contactUs": "联系我们",
    "KRF.understandDeke": "了解德科",
    "KRF.telephone": "座机",
    "KRF.phoneAndWeixin": "手机 & 微信",
    "KRF.PleaseOrderNum": "请输入订单号",
    "KRF.ArticleNo": "货号",
    "KRF.TotalVolume": "总体积",
    "KRF.totalQTY": "总数量",
    "KRF.totalItems": "总款数",
    "KRF.ExhibitionRelatedProducts": "展会关联产品",
    "KRF.ProductsVideo": "产品视频",
    "KRF.ReleaseDate": "发布日期",
    "KRF.inquirySheet": "询价单",
    "KRF.SendInquiry": "发询价单",
    "KRF.productPrice": "产品价格",
    "KRF.OuterBoxCBM": "外箱体积",
    "KRF.OuterBoxCUFT": "外箱材积",
    "KRF.Innerboxes": "内盒个数",
    "KRF.OuterCapacity": "外箱装量",
    "KRF.productDetails": "产品详情",
    "KRF.similarProduct": "相似产品",
    "KRF.MyRFQ": "我的询价单",
    "KRF.enquiry": "询价",
    "KRF.enquirys": "请留下您的电子邮件，我们会与您联系",
    "KRF.contact": "联系方式",
    "KRF.Description": "名称",
    "KRF.OuterQTY": "外箱装量",
    "KRF.LastSevenDays": "最近七天",
    "KRF.All": "全部",
    "KRF.PleaseEmial": "请输入邮箱",
    "KRF.PleaseCode": "请输入验证码",
    "KRF.PleasePhone": "请输入手机号",
    "KRF.PleaseName": "请输入用户名",
    "KRF.userName": "用户名",
    "KRF.PleaseAddress": "请输入联系地址",

    "KRF.loginTitle": "使用账号登录",
    "KRF.login": "登录",
    "KRF.PleaseAccount": "请输入用户名或邮箱",
    "KRF.PleasesAccount": "请输入用户名",
    "KRF.PhoneRoEmail": "手机号或邮箱",
    "KRF.passwordEmpty": "密码不能为空",
    "KRF.Password": "密码",
    "KRF.PleasePassword": "请输入密码",
    "KRF.news": "新闻",
    "KRF.Video": "视频中心",
    "KRF.Search": "搜索",
    "KRF.LearnMore": "了解更多",
    "KRF.AdvancedSearch": "高级搜索",
    "KRF.close": "收起",
    "KRF.open": "展开",
    "KRF.Emial": "邮箱",
    "KRF.phone": "电话",
    "KRF.WeChat": "微信",
    "KRF.updateData": "修改资料",
    "KRF.confirm": "确定",
    "KRF.updateSuccessfully": "更新成功",
    "KRF.UpdateFailure": "更新失败",
    "KRF.Logout": "退出登录",
    "KRF.MyOrders": "我的订单",
    "KRF.Cart": "购物车",
    "KRF.Hello": "您好",
    "KRF.hotProduct": "热销产品",
    "KRF.Country": "国家",
    "KRF.PleaseCountry": "请输入国家",
    "KRF.BrandZone": "品牌专区",
    "KRF.ViewMore": "查看更多",
}
