/*
 * @Description: 互发不锈钢中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: xie
 * @LastEditTime: 2024-08-16 12:01:08
 */
export default {
    "HF.login": "登录",
    "HF.register": "注册",
    "HF.logout": "退出",
    "HF.MyOrder": "我的订单",
    "HF.logoBox.placeholder": "输入关键字",
    "HF.logoBox.search": "搜索",
    "HF.logoBox.AdvancedSearch": "高级搜索",
    "HF.nav.Products": "产品",
    "HF.nav.news": "新闻",
    "HF.ViewPrice": "查看价格",
    "HF.SameFactory": "同一个工厂",
    "HF.newPro": "最新产品",
    "HF.AnotherBatch": "换一批",
    "HF.ViewMore": "查看更多",
    "HF.hotPro": "热销产品",
    "HF.hotVidio": "热点视频",
    "HF.proUnBoxVidio": "开箱视频",
    "HF.Search": "搜索",
    "HF.Reset": "重置",
    "HF.ItemNO": "项目编号",
    "HF.MTCode": "公司编号",
    "HF.FTYCode": "出厂货号",
    "HF.Kind": "种类",
    "HF.Size": "产品规格",
    "HF.Price": "价格",
    "HF.Date": "日期",
    "HF.Sort": "排序",
    "HF.DateDesc": "日期降序",
    "HF.DateAsc": "日期升序",
    "HF.PriceDesc": "价格降序",
    "HF.PriceAsc": "价格升序",
    "HF.NO.Desc": "公司编号降序",
    "HF.NO.Asc": "公司编号升序",
    "HF.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "HF.PleaseSelect": "请选择",
    "HF.PleaseLength": "长",
    "HF.PleaseWidth": "宽",
    "HF.PleaseHeight": "高",
    "HF.PleaseMinPrice": "最小价格",
    "HF.PleaseMaxPrice": "最大价格",
    "HF.PleaseStartDate": "开始日期",
    "HF.PleaseEndDate": "结束日期",
    "HF.UserAccount": "用户名",
    "HF.password": "密码",
    "HF.GROUPSEARCH": "综合搜索",
    "HF.Video_playback": "视频播放",
    "HF.loginMsg": "访问您的帐户和订单历史记录",
    "HF.onUserAccount": "没有账号",
    "HF.SignUp": "立即注册",
    "HF.ForgetPassword": "忘记密码",
    "HF.resetPasswords": "重置密码",
    "HF.emailAddress": "电子邮箱",
    "HF.verifyCode": "验证码",
    "HF.getVerifyCode": "获取验证码",
    "HF.newPassword": "新密码",
    "HF.confirmPassword": "确认密码",
    "HF.Submit": "提交",
    "HF.SignUpAccount": "注册账号",
    "HF.LoginOnly": "仅用于登录",
    "HF.Name": "姓名",
    "HF.NameMsg": "请输入您的姓名，2~30个字符",
    "HF.passwordMsg": "至少6个字符",
    "HF.requiredFields": "*必填项",
    "HF.Email": "邮箱",
    "HF.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "HF.gender": "性别",
    "HF.man": "男士",
    "HF.woman": "女士",
    "HF.customerSource": "客户来源",
    "HF.salesman": "销售员",
    "HF.Country": "国家",
    "HF.Company": "公司",
    "HF.duty": "职务",
    "HF.tel": "电话",
    "HF.mobile": "手机号",
    "HF.fax": "传真",
    "HF.Yahoo": "雅虎",
    "HF.website": "网站",
    "HF.address": "地址",
    "HF.postalCode": "邮政编码",
    "HF.ExistingAccount": "已有账号",
    "HF.DirectLogin": "直接登录",
    "HF.productPrice": "产品价格",
    "HF.ProductNumber": "产品编号",
    "HF.Packing": "包装方式",
    "HF.OuterBoxCBM": "外箱体积",
    "HF.OuterBoxCUFT": "外箱材积",
    "HF.Innerboxes": "内盒个数",
    "HF.OuterQTY": "外箱装量",
    "HF.GWAndNW": "毛重 / 净重",
    "HF.productSize": "产品规格",
    "HF.packageSize": "包装规格",
    "HF.cartonSize": "外箱规格",
    "HF.addCart": "添加购物车",
    "HF.delCart": "移出购物车",
    "HF.product": "产品",
    "HF.NewsUpdates": "新闻动态",
    "HF.ReleaseDate": "发布日期",
    "HF.MyCart": "我的购物车",
    "HF.ID": "ID",
    "HF.picture": "图片",
    "HF.ArticleNo": "货号",
    "HF.Description": "名称",
    "HF.CartOuterQTY": "外箱装量",
    "HF.CBM": "体积",
    "HF.CTNS": "箱数",
    "HF.totalQTY": "总数量",
    "HF.TotalVolume": "总体积",
    "HF.total": "合计",
    "HF.delete": "删除",
    "HF.checkAll": "全选",
    "HF.clear": "清空",
    "HF.totalItems": "总款数",
    "HF.totalCTNS": "总箱量",
    "HF.noData": "暂无数据",
    "HF.totalPrice": "总价",
    "HF.systemTip": "系统提示",
    "HF.confirm": "确定",
    "HF.cancel": "取消",
    "HF.deleteData": "是否删除该条数据？",
    "HF.clearCartData": "是否清空全部购物车？",
    "HF.submitTip": "确认提交订单？",
    "HF.MyOrder": "我的订单",
    "HF.PleaseOrderNum": "请输入订单号",
    "HF.number": "单号",
    "HF.viewDetails": "查看详情",
    "HF.orderTime": "创建时间",
    "HF.linkman": "联系人",
    "HF.sendDate": "发货时间",
    "HF.expressNumber": "快递单号",
    "HF.orderStatus": "订单状态",
    "HF.unshipped": "未发货",
    "HF.shipped": "已发货",
    "HF.SiteNavigation": "网站导航",
    "HF.ContactUs": "联系我们",
    "HF.FollowUs": "关注我们",
    "HF.Landline": "座机",
    "HF.Email": "邮箱",
    "HF.Phone": "手机",
    "HF.WeChat": "微信号",
    "HF.Technic": "技术支持",
    "HF.Software": "深圳宏升软件技术开发有限公司",
    "HF.detailBtn": "产品详情",
    "HF.noDetailBtn": "暂无产品详情",
    "HF.classify": "产品分类",
    "HF.exclusive": "专属推荐",
    "HF.loading": "加载中...",
    "HF.updateTime": "更新时间",
    "HF.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "HF.MessageTitle": "在线提交您的需求",
    "HF.MessageMsg": "我们会在24小时内回复您的邮件，节假日除外。我们期待听到您的声音，帮助您解决问题，回答疑虑，共同探讨合作机会。感谢您选择我们，我们将竭诚为您服务！",
    "HF.MessageName": "名字",
    "HF.MessageTel": "您的手机",
    "HF.MessageEmail": "邮箱",
    "HF.MessageNeeds": "消息",
    "HF.SendInquiry": "发询价单",
    "HF.EnquiryList": "询价单",
    "HF.enquiry": "询价",
    "HF.MyEnquiryList": "我的询价单",
    "HF.Remark": "备注",
    "HF.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "HF.proClass": "产品分类",
    "HF.submitOrder": "确认提交询单？",
    "HF.Co-branding": "合作品牌",
    "HF.Co-brandingMsg": "已经有100余家企业，与我司进行深入合作",
    "HF.recommendPro": "推荐产品",
    "HF.Blog": "博客",
    "HF.More": "更多",
    "HF.nav.Home": "主页",
    "HF.nav.category": "类目",
    "HF.nav.Hot": "热卖",
    "HF.nav.Gift": "礼物",
    "HF.nav.New": "新产品",
    "HF.nav.video": "视频",
    "HF.nav.Blog": "博客",
    "HF.nav.About": "关于我们",
    "HF.nav.contact": "联系我们",
    "HF.nav.FooterNew": "新品",
    "HF.news.title": "所有博客",
    "HF.noMore": "没有更多了",
    "HF.top.cart": "购物车",
    "HF.fomcat.title": "为什么选择fomcat品牌",
    "HF.fomcat.titleMsg": "优势尽在这里！ Fomcat玩具批发商的优势不容忽视，让我们看看为什么我们是您的最佳合作伙伴！",
    "HF.fomcat1.title1": "多样化的产品系列",
    "HF.fomcat1.Msg1": "涵盖儿童玩具、遥控玩具、模型和收藏品等，满足不同客户需求。无论您的店铺面向哪个年龄段，我们都有完美的选择！",
    "HF.fomcat2.title1": "材料品质保证",
    "HF.fomcat2.Msg1": "我们精选优质材料，为孩子们打造安全可靠的玩具，经受市场检验，让家长和孩子们安心享乐！",
    "HF.fomcat3.title1": "竞争力的批发价格",
    "HF.fomcat3.Msg1": "我们提供极具竞争力的批发价格，为您带来更大的利润空间！",
    "HF.fomcat4.title1": "轻松合作",
    "HF.fomcat4.Msg1": "随时与我们联系，我们的团队将高效解决您的问题和需求。我们的目标是建立长期成功的合作关系！",
    "HF.fomcat5.title1": "方便订购",
    "HF.fomcat5.title2": "贴心服务",
    "HF.fomcat5.Mag1": "您的成功就是我们的成功！选择Fomcat品牌，与我们一起打造美好的玩具乐园！",
    "HF.userGuide": "用户指南",
    "HF.internationalTransport": "国际运输",
    "HF.cardTitle1": "关于牵梦科技",
    "HF.cardTitle2": "牵梦科技的物流和运输服务",
    "HF.cardMsg": "可以代表您管理从中国进口货物的整个物流过程；也可以彻底改变您从中国采购和进口的方式，节省您的业务时间和采购成本。",
    "HF.card1Title": "我们的优势",
    "HF.card2.Title1": "物流流程",
    "HF.card2.Title01": "出厂发货",
    "HF.card2.Title02": "中国出货量",
    "HF.card2.Title03": "中国仓库",
    "HF.card2.Title04": "中国海关",
    "HF.card2.Title05": "国际配送",
    "HF.card2.Title06": "全球海关",
    "HF.card2.Title07": "全球国内运输",
    "HF.card3.Title": "物流服务包括",
    "HF.card3.msg1": "提供从供应商工厂到您的收货地址的完整物流服务。这使我们的客户能够随时监控他们的货物在进口过程中的位置。",
    "HF.card3.msg2": "我们在中国拥有超过70,000平方米的仓库，我们在那里接收、加工和储存货物，直到它们运往全球。",
    "HF.card3.li": "从工厂接收货物",
    "HF.card3.li2": "存储",
    "HF.card3.li3": "货物包装",
    "HF.card3.li4": "质量管理",
    "HF.card3.li5": "产品生产合格证",
    "HF.card3.li6": "报关",
    "HF.card3.li7": "其他服务",
    "HF.card4.title": "有关国际运输和国际交付的信息",
    "HF.card4.cell.title": "估计费用",
    "HF.card4.cell.msg1": "为了提供运输报价，我们需要知道您订单中包含的物品是否受进出口限制、工厂是否有库存、尺寸、产品的确切重量等。",
    "HF.card4.cell.msg2": "这就是为什么我们的团队需要联系供应商以了解此类信息并提供成本估算的原因。",
    "HF.card4.cell.msg3": "您将收到三种可用运输方式（海运、空运、联邦快递）的运输成本、立方体积和交货时间的详细估算。",
    "HF.card4.cell2.title": "最终报价和运输",
    "HF.card4.cell2.msg1": "只有在货物到达我们的仓库后，才能获得最好的运输报价。因此，准确的运费报价和其他费用是在我们收到货物后计算的。",
    "HF.card4.cell2.msg2": "一旦物品到达我们的仓库，我们将对货物进行分组，以提供最优惠的价格。",
    "HF.card4.cell3.title": "货运明细",
    "HF.card4.cell3.msg1": "国际运费。",
    "HF.card4.cell3.msg2": "寄往全球的国际运费。",
    "HF.card4.cell3.msg3": "全球国内物流费用。",
    "HF.card4.cell3.msg4": "全球清关后，我们会根据收货地址安排国内发货。",
    "HF.card4.cell4.title": "危险品运输",
    "HF.card4.cell4.msg1": "危险品是具有以下特征和属性的物品：威胁人类生命和健康；对环境造成无法弥补的损害；对物理对象造成损坏。",
    "HF.card4.cell4.msg2": "这些货物包括：动物和动物产品、植物产品、动物和植物油脂、食品代理、普通金属、精密仪器、武器、弹药和艺术品。",
    "HF.card4.cell5.title": "选择国际船公司",
    "HF.card4.cell5.msg1": "将为您的货物选择可靠、快速、经济的国际物流渠道。经过仔细评估，我们综合考虑了运输成本、运输时效和服务稳定性等因素。",
    "HF.card4.cell5.msg2": "我们将不断努力寻找最佳的物流合作伙伴和运输路线，在我们的平台上为您服务。",
    "HF.card4.cell6.title": "报关",
    "HF.card4.cell6.msg1": "清关将根据您在要求交货时选择的清关方式（海运、空运或陆运）确定。",
    "HF.card4.cell6.msg2": "我公司负责办理相关清关事宜。我司将负责相关清关事宜。客户也可以选择自行办理清关手续。",
    "HF.contact.title": "您的满意是我们最大的追求。如果您有任何问题、建议或合作意向，请随时联系我们。我们随时为您提供支持和帮助。",
    "HF.cacontact.card1.title": "联系方式",
    "HF.cacontact.card1.msg": "为你想得更多，为你做得更好",
    "HF.Official.website": "官方网站",
    "HF.link": "点击图片，转到官方网站",
    "HF.about.subject": "Fomtoy使进口更容易",
    "HF.about.msg": "Fomtoy是一个一体化的进口平台，我们将为您提供数以万计的供应商，使您能够以更具竞争力的价格进口产品。我们也会为您管理整个采购、物流和进口流程。",
    "HF.about.card1.title": "中国最强大的B2B电子商务解决方案",
    "HF.about.card1.content": "Fomtoy是一家B2B公司，其主要目标是帮助企业以最具成本效益的方式从中国进口产品，节省成本并简化流程，并为客户提供最佳的进口体验。我们的总部位于中国苏州，拥有4000多平方米的办公运营面积和15000平方米的仓库。我们在集团公司拥有300名员工和一支独立的技术研发团队。",
    "HF.about.card1.label": "现货产品",
    "HF.about.card1.label2": "专利",
    "HF.about.card1.label3": "新产品",
    "HF.about.card1.label4": "国家",
    "HF.about.card2.title": "我们的优势",
    "HF.about.card2.msg": "我们不仅敢承诺，而且能做到",
    "HF.about.card2.cell.tit": "简化您从中国的采购和进口，优化所有成本",
    "HF.about.card2.cell.msg": "英文界面使您可以轻松完成采购流程。提供质量控制、仓储、国际物流、清关、附加服务等服务。英语客服方便沟通和售后",
    "HF.about.card2.cell2.tit": "优化运输和进口成本",
    "HF.about.card2.cell2.tit2": "从单个站点导入的整个过程",
    "HF.about.card2.cell2.tit3": "仓储和全面进口管理",
    "HF.about.card2.cell2.tit4": "双语客服及售后服务",
    "HF.about.card2.cell3.tit": "找到你想要的一切世界工厂就在你手中",
    "HF.about.card2.cell3.msg": "目前，用户可以在Rakumart上找到阿里巴巴、1688和其他大型中国国内电子商务平台的任何产品。我们庞大的合作网络使我们能够为您提供各种各样的产品和超过100000家优质的中国供应商。",
    "HF.about.card3.title": "我们在世界各地都有各种业务的客户，听听他们对我们的评价",
    "HF.about.card3.msg": "在世界各地寻找我们的位置？",
    "HF.about.card3.cell.tit": "Fomtoy的魔力：无缝处理订单的幕后故事",
    "HF.about.card3.cell.msg": "在遇到FulfillPanda之前，我一直被每天的订单量所困扰。他们的无缝订单处理系统真正改变了游戏规则，使我能够专注于我的业务，而不用担心库存问题。",
    "HF.about.card3.cell2.tit": "每天3000个订单：我的快速扩张之旅",
    "HF.about.card3.cell2.msg": "我的商业之旅面临着挑战，但随着时间的推移，在FulfillPanda的帮助下，我现在每天处理3000多个订单。这种快速扩张不仅仅是数量上的增长；这证明了我们团队在Fomtoy支持下的辛勤工作。",
    "HF.about.card3.cell3.tit": "超越预期：FulfillPanda提供的不仅仅是服务",
    "HF.about.card3.cell3.msg": "在与FulfillPanda的合作中，我发现他们提供的不仅仅是一项服务。他们密切关注我的业务，提供超出传统服务提供商期望的解决方案。这是一种真诚而热情的合作关系，远远超出了商业领域",
    "HF.about.card3.cell4.tit": "Fulfillpanda是一个救生...",
    "HF.about.card3.cell4.msg": "即使我有货运代理，我也很难按比例缩放。由于我运行了17个普通商店，原因不同，比如商店备份，使用不同的广告帐户进行测试。我们每天测试50种产品以寻找赢家。我的团队不得不不时地向其他平台发布采购请求。这很耗时。同时，FulfillPanda会密切关注您的每一步，让您了解有关库存问题的任何信息，并随时提出解决方案。多亏了他们，我们每天要做3000个订单。大多数订单都及时发货。一定会推荐FulfillPanda！",
    "HF.about.card4.tit": "我们的使命",
    "HF.about.card4.msg": "我们的使命是提供具有卓越价值的优质产品。我们倾听我们的客户和制造商。我们观察他们生活的世界，并为他们提供多个类别的商品的新趋势。我们对我们所代表的公司以及他们开发和创造的趋势和创新产品充满热情。我们一直在寻找令人难以置信的想法，我们渴望将这些非凡的新产品推向每个市场。",
    "HF.about.card4.subtitle": "01.我们的价值",
    "HF.about.card4.cell.tit": "目标导向",
    "HF.about.card4.cell.msg": "我们仍然专注于保持盈利增长，并为我们的制造商扩大分销领域。",
    "HF.about.card4.cell.tit2": "诚信",
    "HF.about.card4.cell.msg2": "我们以诚信和高道德标准运作，并在我们所努力的一切。",
    "HF.about.card4.cell.tit3": "职业道德",
    "HF.about.card4.cell.msg3": "勤奋和坚持是我们业务的每一个方面的根本。在这个充满挑战的时代，我们非常努力地工作。",
    "HF.about.card4.cell.tit4": "注重细节",
    "HF.about.card4.cell.msg4": "我们认识到，小问题需要更多的关注，然后更大的事情会照顾自己。",
    "HF.about.card4.subtitle2": "02.视野",
    "HF.about.card4.subtitle2.msg": "我们的目标是通过倾听制造商、零售商和消费者的意见，成为为制造商提供更多分销的领导者。高组织体系中的利润和结构将创造成功。我们努力用他们不知道的产品来激励我们的客户群，他们从不知道自己需要或存在。然而，一旦他们对我们的产品有了嗜好，他们就无法理解没有它们的存在。",
    "HF.about.card5.Company": "公司总部",
    "HF.about.card5.info": "中国，苏州",
    "HF.about.card5.address": "公司地址",
    "HF.about.card5.addressName": "江苏省苏州市工业园区1818写字楼18楼",
    "HF.about.card5.Telephone": "联系电话",
    "HF.Hi": "嗨~",
    "HF.companyProfile": "公司简介",
    "HF.stainlessSteelProductFromHufa": '互发不锈钢制品',
    "HF.hufaFactoryWorkshop": '互发工厂车间',
    "HF.showroom": '互发展厅',
    "HF.hufaExhibition": '互发展览',
    "HF.customerWall": '客户墙',
    "HF.shippingMoment": '装运时刻',
    "HF.ourCertificate": '我的证书',
    "HF.ourCertificate.text": '我们的质量认证是我们的承诺。我们库存中的每一件厨房用具都经过精心制作，以满足最高的安全性和耐用性标准。别担心，我知道你的笑容会一直持续下去。',
    "HF.qq": "QQ",
    "HF.whatsapp": "Whatsapp",
    "HF.wechat": "微信",
    "HF.outerBox": "外箱装量",
    "HF.innerBox": '内盒个数',
    "HF.moreBlogs": '更多博客',
    "HF.corporateName": '潮州市潮安区彩塘互发不锈钢厂',
    "HF.totalQuantity": "总数量",
    "HF.showroom.title": "工厂展厅",
    "HF.showroom.item1": "600㎡的大展厅，展出3000多种不同产品",
    "HF.showroom.item2": "50多台自动化生产机器",
    "HF.showroom.item3": "工厂生产面积达到9800㎡",
    "HF.showroom.item4": "拥有国内外独家专利技术",
    "HF.showroom.item5": "欢迎OEM和ODM",
    "HF.showroom.item6": "工厂地址：",
    "HF.exhibition.title": "义乌展厅",
    "HF.exhibition.item1": "义乌三大厨具展示厅",
    "HF.exhibition.item2": "3000多种酒店用品和家用电器",
    "HF.exhibition.item3": "义乌国际城最优秀、规模最大的团队",
    "HF.exhibition.item4": "50多种不同系列厨具的现成专业报价单",
    "HF.exhibition.item5": "提供最优惠的价格和最高效的服务",
    "HF.exhibition.item6": "地址: 义乌国际城4楼22门19070号铺",
    "HF.contactUs.questions": "我们致力于为您提供个性化服务。如有任何疑问，请联系我们",
    "HF.contactUs.ideas": "告诉我们你的项目想法，或者只是打个招呼。无论您是有一个伟大的想法，还是需要一些建筑项目的灵感，我们都在这里创造完美的建筑，从概念到创作，让我们激励您。",
    "HF.contactUs.work": "我们愿意与您合作",
    "HF.contactUs.quotationAccordingly": "请选择您想要的商品并将其放入购物车，然后与我们联系。或者您可以在登录页面中留下您的姓名和电子邮件，然后我们可以相应地向您发送报价。",
    "HF.submitMessage": "提交消息",
    "HF.QTY":"外箱装量"
}
