/*
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: xie
 * @LastEditTime: 2024-07-31 17:50:55
 */
import VueI18n from 'vue-i18n'
import Vue from 'vue'

//引入element的语言包
import enLocale from 'element-ui/lib/locale/lang/en' //英文
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //中文
import ruLocale from 'element-ui/lib/locale/lang/ru-RU' //俄语

// 引入需要语言包也可是js文件，export default抛出语言包的对象
import en from './lang/saas-en.js'
import zh from './lang/saas-zh-CN.js'
import ru from './lang/saas-ru-RU.js'

// 德科语言
import enDeKe from './lang/deKe/saas-en.js'
import zhDeKe from './lang/deKe/saas-zh-CN.js'
import ruDeKe from './lang/deKe/saas-ru-RU.js'

// 凯荣丰语言
import enKRF from './lang/KRF/saas-en.js'
import zhKRF from './lang/KRF/saas-zh-CN.js'
import ruKRF from './lang/KRF/saas-ru-RU.js'

// 锦泰国际语言
import enJT from './lang/jinTai/saas-en.js'
import zhJT from './lang/jinTai/saas-zh-CN.js'
import ruJT from './lang/jinTai/saas-ru-RU.js'

// 国安语言
import enGA from './lang/guoAn/saas-en.js'
import zhGA from './lang/guoAn/saas-zh-CN.js'
import esGA from './lang/guoAn/saas-es.js'

// 多明戈语言
import enDMG from './lang/DuoMingGe/saas-en.js'
import zhDMG from './lang/DuoMingGe/saas-zh-CN.js'
import esDMG from './lang/DuoMingGe/saas-es.js'

// 国安语言
import enNTMZ from './lang/NanTongMuZhi/saas-en.js'
import zhNTMZ from './lang/NanTongMuZhi/saas-zh-CN.js'
import esNTMZ from './lang/NanTongMuZhi/saas-es.js'

// 互发语言
import enHF from './lang/HF/saas-en.js'
import zhHF from './lang/HF/saas-zh-CN.js'
import ruHF from './lang/HF/saas-ru-RU.js'
Vue.use(VueI18n)
let lang = localStorage.getItem('i18n') || 'en' || 'ru' || 'es'

export default new VueI18n({
    locale: lang,
    messages: {
        en: Object.assign(en, enDeKe, enKRF, enJT, enGA, enLocale, enDMG, enNTMZ,enHF),
        zh: Object.assign(zh, zhDeKe, zhKRF, zhJT, zhGA, zhLocale, zhDMG, zhNTMZ,zhHF),
        ru: Object.assign(ru, ruDeKe, ruKRF, ruJT, ruLocale,ruHF), // 俄语
        es: Object.assign(esGA, esNTMZ, esDMG), // 西班牙语
    }
})