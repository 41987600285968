/*
 * @Description: 购物车及多语言切换
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-03-28 10:36:40
 */
export const CART = 'cart'
import { DELETE_CART, CLEAR_CART, SET_LANGUAGE, SET_CART, ADD_CART, SET_ENQUIRY, DELETE_ENQUIRY } from '../mutation-types.js'

const operate = {
	namespaced: true,
	state: {
		language: localStorage.getItem('i18n') || 'en',
		cart: [],
		enquiry: JSON.parse(localStorage.getItem('enquiry')) || [{ date: '', list: [] }], // 询价产品数据
	},
	mutations: {
		// 设置询价
		[SET_ENQUIRY](state, info) {
			let date = new Date()
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			let day = date.getDate()
			let dates = year + '-' + month + '-' + day
			let timestamp = Date.parse(dates) / 1000

			let arr = [{ date: timestamp, list: state.enquiry[0].list }]

			state.enquiry.forEach((item, index) => {
				if (item.date == timestamp) {
					const ind = item.list.findIndex(it => it.co_Nu === info.co_Nu)
					if (ind > -1) {
						arr[index].list.splice(ind, 1)
					}
					if (info._num_) {
						info.number = 1
						arr[index].list.push(info)
					}
				} else {
					info.number = 1
					arr[index].list = [info]
				}
			})
			state.enquiry = arr
			localStorage.setItem('enquiry', JSON.stringify(arr))
		},
		// 从询价删除商品
		[DELETE_ENQUIRY](state, item) {
			if (!item) {
				state.enquiry[0].list = []
			}
			const ind = state.enquiry[0].list.findIndex(it => it.co_Nu === item.co_Nu)
			state.enquiry[0].list.splice(ind, 1)
			let obj = {
				date: state.enquiry[0].date,
				list: state.enquiry[0].list
			}
			localStorage.setItem('enquiry', JSON.stringify([obj]))
		},


		[SET_CART](state, info) {
			state.cart = info
		},
		// 加入购物车
		[ADD_CART](state, info) {
			const index = state.cart.findIndex(item => item.co_Nu === info.co_Nu)
			if (index > -1) {
				state.cart[index].number = 0
				state.cart[index].number += info.number
			} else {
				state.cart.push(info)
			}
		},
		// 从购物车删除商品
		[DELETE_CART](state, co_Nu) {
			const index = state.cart.findIndex(item => item.co_Nu === co_Nu)
			if (index > -1) {
				state.cart.splice(index, 1)
			}
		},
		[CLEAR_CART](state) {
			state.cart = []
		},
		// 设置语言
		[SET_LANGUAGE](state, language) {
			state.language = language
			localStorage.setItem('i18n', language)
		}
	},
	actions: {
		// 设置询价
		set_enquiry({ commit }, product) {
			commit(SET_ENQUIRY, product)
		},
		// 删除询价
		delete_enquiry({ commit }, item) {
			commit(DELETE_ENQUIRY, item)
		},

		set_cart({ commit }, product) {
			commit(SET_CART, product)
		},
		// 添加购物车
		add_cart({ commit }, product) {
			commit(ADD_CART, product)
		},
		// 批量添加购物车
		batch_add_cart({ commit }, productList) {
			if (productList.length > 0) {
				productList.forEach(item => {
					commit(ADD_CART, item)
				})
			}
		},

		// 删除购物车
		delete_cart({ commit }, co_Nu) {
			commit(DELETE_CART, co_Nu)
		},
		// 批量删除购物车
		batch_delete_cart({ commit }, co_Nus) {
			if (co_Nus.length > 0) {
				co_Nus.forEach(item => {
					commit(DELETE_CART, item)
				})
			}
		},
		// 清空购物车
		clear_cart({ commit }) {
			commit(CLEAR_CART)
		},
		// 设置语言
		set_language({ commit }, language) {
			commit(SET_LANGUAGE, language)
		}
	},
	getters: {
		// 获取询价产品数据
		get_enquiry: state => {
			return state.enquiry
		},
		getting_cart: state => {
			return state.cart
		}
	}
}

export default operate
