/*
 * @Description: 获取公司信息
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-23 09:37:39
 */
import request from '@/utils/request'

// 获取公司简介
export function getCompanyInfo(data) {
  return request({
    url: 'front/Information/GetAboutInfo',
    method: 'post',
    data
  })
}
// 获取轮播图列表
export function CarouselGetList(data) {
  return request({
    url: 'front/Carousel/GetList',
    method: 'post',
    data
  })
}

// 获取联系我们
export function GetContactInfo(data) {
  return request({
    url: 'front/Information/GetContactInfo',
    method: 'post',
    data
  })
}

// 获取网站配置信息
export function SiteSettingWebGetInfo(data) {
  return request({
    url: 'front/SiteSettingWeb/GetInfo',
    method: 'post',
    data
  })
}

// 根据租户获取网站设置配置设置明细
export function SysWebSiteConfigGetDetail(data) {
  return request({
    url: 'admin/SysWebSiteConfig/GetDetail',
    method: 'post',
    data
  })
}

// 获取网站配置信息
export function ProductClassWebGetTree(data) {
  return request({
    url: 'front/ProductClassWeb/GetTree',
    method: 'post',
    data
  })
}

// 获取新品列表
export function GetProductListByDate(data) {
  return request({
    url: 'front/Home/GetProductListByDate',
    method: 'post',
    data
  })
}

// 获取样品折扣/推荐/热销列表
export function GetProductListByDiscount(data) {
  return request({
    url: 'front/Home/GetProductListByDiscount',
    method: 'post',
    data
  })
}

// 获取网站配置列表
export function SiteSettingWebGetSiteList(data) {
  return request({
    url: 'front/SiteSettingWeb/GetSiteList',
    method: 'post',
    data
  })
}

// 获取分类样品列表
export function GetProductListByClass(data) {
  return request({
    url: 'front/Home/GetProductListByClass',
    method: 'post',
    data
  })
}

// 获取视频列表
export function VideoGetList(data) {
  return request({
    url: 'front/Video/GetList',
    method: 'post',
    data
  })
}
// 获取分页视频列表
export function VideoGetListPage(data) {
  return request({
    url: 'front/Video/QueryPage',
    method: 'post',
    data
  })
}

// 获取样品种类推荐列表
export function ProductClassRecommendWebGetList(data) {
  return request({
    url: 'front/ProductClassRecommendWeb/GetList',
    method: 'post',
    data
  })
}

// 获取品牌推荐列表
export function BrandRecommendationWebGetList(data) {
  return request({
    url: 'front/BrandRecommendationWeb/GetList',
    method: 'post',
    data
  })
}

// 获取品牌推荐列表
export function AboutUsWebGetInfo(data) {
  return request({
    url: 'front/AboutUsWeb/GetInfo',
    method: 'post',
    data
  })
}
// 获取分类图片列表
export function appendixGetList(data) {
  return request({
    url: '/front/Appendix/GetList',
    method: 'post',
    data
  })
}

