/*
 * @Description: 国安中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-18 09:20:15
 */
export default {
    "GA.login": "登录",
    "GA.register": "注册",
    "GA.logout": "退出",
    "GA.MyOrder": "我的订单",
    "GA.logoBox.placeholder": "请输入产品关键字",
    "GA.logoBox.search": "搜索",
    "GA.logoBox.AdvancedSearch": "高级搜索",
    "GA.nav.Home": "首页",
    "GA.nav.Products": "产品",
    "GA.nav.About": "关于我们",
    "GA.nav.contact": "联系我们",
    "GA.nav.news": "新闻",
    "GA.nav.cart": "购物车",
    "GA.ViewPrice": "查看价格",
    "GA.SameFactory": "同一个工厂",
    "GA.newPro": "最新产品",
    "GA.AnotherBatch": "换一批",
    "GA.ViewMore": "查看更多",
    "GA.hotPro": "热销产品",
    "GA.hotVidio": "热点视频",
    "GA.proUnBoxVidio": "开箱视频",
    "GA.Search": "搜索",
    "GA.Reset": "重置",
    "GA.ItemNO": "项目编号",
    "GA.MTCode": "公司编号",
    "GA.FTYCode": "出厂货号",
    "GA.Kind": "种类",
    "GA.Size": "产品规格",
    "GA.Price": "价格",
    "GA.Date": "日期",
    "GA.Sort": "排序",
    "GA.DateDesc": "日期降序",
    "GA.DateAsc": "日期升序",
    "GA.PriceDesc": "价格降序",
    "GA.PriceAsc": "价格升序",
    "GA.NO.Desc": "公司编号降序",
    "GA.NO.Asc": "公司编号升序",
    "GA.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "GA.PleaseSelect": "请选择",
    "GA.PleaseLength": "长",
    "GA.PleaseWidth": "宽",
    "GA.PleaseHeight": "高",
    "GA.PleaseMinPrice": "最小价格",
    "GA.PleaseMaxPrice": "最大价格",
    "GA.PleaseStartDate": "开始日期",
    "GA.PleaseEndDate": "结束日期",
    "GA.UserAccount": "用户名",
    "GA.password": "密码",
    "GA.GROUPSEARCH": "综合搜索",
    "GA.Video_playback": "视频播放",
    "GA.loginMsg": "访问您的帐户和订单历史记录",
    "GA.onUserAccount": "没有账号",
    "GA.SignUp": "立即注册",
    "GA.ForgetPassword": "忘记密码",
    "GA.resetPasswords": "重置密码",
    "GA.emailAddress": "电子邮箱",
    "GA.verifyCode": "验证码",
    "GA.getVerifyCode": "获取验证码",
    "GA.newPassword": "新密码",
    "GA.confirmPassword": "确认密码",
    "GA.Submit": "提交",
    "GA.SignUpAccount": "注册账号",
    "GA.LoginOnly": "仅用于登录",
    "GA.Name": "姓名",
    "GA.NameMsg": "请输入您的姓名，2~30个字符",
    "GA.passwordMsg": "至少6个字符",
    "GA.requiredFields": "*必填项",
    "GA.Email": "邮箱",
    "GA.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "GA.gender": "性别",
    "GA.man": "男士",
    "GA.woman": "女士",
    "GA.customerSource": "客户来源",
    "GA.salesman": "销售员",
    "GA.Country": "国家",
    "GA.companyName": "公司名称",
    "GA.duty": "职务",
    "GA.tel": "电话",
    "GA.mobile": "手机号",
    "GA.fax": "传真",
    "GA.Yahoo": "雅虎",
    "GA.website": "网站",
    "GA.address": "地址",
    "GA.postalCode": "邮政编码",
    "GA.ExistingAccount": "已有账号",
    "GA.DirectLogin": "直接登录",
    "GA.productPrice": "产品价格",
    "GA.ProductNumber": "产品编号",
    "GA.Packing": "包装方式",
    "GA.OuterBoxCBM": "外箱体积",
    "GA.OuterBoxCUFT": "外箱材积",
    "GA.Innerboxes": "内盒个数",
    "GA.OuterQTY": "外箱装量",
    "GA.GWAndNW": "毛重 / 净重",
    "GA.productSize": "产品规格",
    "GA.packageSize": "包装规格",
    "GA.cartonSize": "外箱规格",
    "GA.addCart": "添加购物车",
    "GA.delCart": "移出购物车",
    "GA.product": "产品",
    "GA.NewsUpdates": "新闻动态",
    "GA.ReleaseDate": "发布日期",
    "GA.MyCart": "我的购物车",
    "GA.ID": "ID",
    "GA.picture": "图片",
    "GA.ArticleNo": "货号",
    "GA.Description": "名称",
    "GA.CartOuterQTY": "外箱装量",
    "GA.CBM": "体积",
    "GA.CTNS": "箱数",
    "GA.totalQTY": "总数量",
    "GA.TotalVolume": "总体积",
    "GA.total": "合计",
    "GA.delete": "删除",
    "GA.checkAll": "全选",
    "GA.clear": "清空",
    "GA.totalItems": "总款数",
    "GA.totalCTNS": "总箱量",
    "GA.noData": "暂无数据",
    "GA.totalPrice": "总价",
    "GA.systemTip": "系统提示",
    "GA.confirm": "确定",
    "GA.cancel": "取消",
    "GA.deleteData": "是否删除该条数据？",
    "GA.clearCartData": "是否清空全部购物车？",
    "GA.submitTip": "确认提交订单？",
    "GA.MyOrder": "我的订单",
    "GA.PleaseOrderNum": "请输入订单号",
    "GA.number": "单号",
    "GA.viewDetails": "查看详情",
    "GA.orderTime": "创建时间",
    "GA.linkman": "联系人",
    "GA.sendDate": "发货时间",
    "GA.expressNumber": "快递单号",
    "GA.orderStatus": "订单状态",
    "GA.unshipped": "未发货",
    "GA.shipped": "已发货",
    "GA.SiteNavigation": "网站导航",
    "GA.ContactUs": "联系我们",
    "GA.FollowUs": "关注我们",
    "GA.Landline": "座机",
    "GA.Email": "邮箱",
    "GA.Phone": "手机号",
    "GA.WeChat": "微信号",
    "GA.Technic": "技术支持",
    "GA.Software": "深圳宏升软件技术开发有限公司",
    "GA.detailBtn": "产品详情",
    "GA.noDetailBtn": "暂无产品详情",
    "GA.classify": "产品分类",
    "GA.exclusive": "专属推荐",
    "GA.loading": "加载中...",
    "GA.updateTime": "更新时间",
    "GA.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "GA.MessageTitle": "在线提交您的需求",
    "GA.MessageMsg": "我们将在24小时内回复您的电子邮件，节假日除外",
    "GA.MessageName": "你的名字",
    "GA.MessageTel": "你的手机",
    "GA.MessageEmail": "你的邮箱",
    "GA.MessageNeeds": "你的需求",
    "GA.SendInquiry": "发询价单",
    "GA.EnquiryList": "询价单",
    "GA.enquiry": "询价",
    "GA.MyEnquiryList": "我的询价单",
    "GA.Remark": "备注",
    "GA.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "GA.proClass": "产品分类",
    "GA.submitOrder": "确认提交询单？",
}
