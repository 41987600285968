/*
 * @Description: 互发不锈钢英文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: xie
 * @LastEditTime: 2024-08-20 14:29:14
 */
export default {
    "HF.login":"Login",
    "HF.register":"Register",
    "HF.logout":"Logout",
    "HF.MyOrder":"My Order",
    "HF.logoBox.placeholder": "Enter keywords",
    "HF.logoBox.search": "Search",
    "HF.logoBox.AdvancedSearch": "Advanced Search",
    "HF.nav.Products": "PRODUCTS",
    "HF.nav.news": "News",
    "HF.ViewPrice": "View Price",
    "HF.SameFactory": "Same Factory",
    "HF.newPro": "NEW ARRIVIALS",
    "HF.AnotherBatch": "Another Batch",
    "HF.ViewMore": "View More",
    "HF.hotPro": "HOT ITEMS",
    "HF.hotVidio": "HOT ITEM VODEO",
    "HF.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "HF.Search": "SEARCH",
    "HF.Reset": "RESET",
    "HF.ItemNO": "Item NO.",
    "HF.MTCode": "MT Code",
    "HF.FTYCode": "FTY Code",
    "HF.Kind": "Kind",
    "HF.Size": "Size",
    "HF.Price": "Price",
    "HF.Date": "Date",
    "HF.Sort": "Sort",
    "HF.DateDesc": "DATE DESC",
    "HF.DateAsc": "DATE ASC",
    "HF.PriceDesc": "PRICE DESC",
    "HF.PriceAsc": "PRICE ASC",
    "HF.NO.Desc": "NO. DESC",
    "HF.NO.Asc": "NO. ASC",
    "HF.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "HF.PleaseSelect": "Please select",
    "HF.PleaseLength": "Length",
    "HF.PleaseWidth": "Width",
    "HF.PleaseHeight": "Height",
    "HF.PleaseMinPrice": "Min price",
    "HF.PleaseMaxPrice": "Max price",
    "HF.PleaseStartDate": "Start date",
    "HF.PleaseEndDate": "End date",
    "HF.UserAccount": "User Account",
    "HF.password": "Password",
    "HF.GROUPSEARCH": "GROUPSEARCH",
    "HF.Video_playback": "Video playing",
    "HF.loginMsg": "Access your account and order history",
    "HF.onUserAccount": "No account",
    "HF.SignUp": "Sign up",
    "HF.ForgetPassword": "Forget password",
    "HF.resetPasswords": "Reset password",
    "HF.emailAddress": "Email address",
    "HF.verifyCode": "Verify code",
    "HF.getVerifyCode": "Get code",
    "HF.newPassword": "New password",
    "HF.confirmPassword": "Confirm password",
    "HF.Submit": "Submit",
    "HF.SignUpAccount": "Sign up",
    "HF.LoginOnly": "Login only",
    "HF.Name": "Name",
    "HF.NameMsg": "Please enter your name, 2 to 30 characters",
    "HF.passwordMsg": "At least 6 characters",
    "HF.requiredFields": "*Required fields",
    "HF.Email": "Email",
    "HF.EmailMsg": "Please enter email. Used for login",
    "HF.gender": "Gender",
    "HF.man": "Mr.",
    "HF.woman": "Ms.",
    "HF.customerSource": "Customer source",
    "HF.salesman": "Contact Salesman",
    "HF.Country": "Country",
    "HF.Company": "Company",
    "HF.duty": "Job Titel",
    "HF.tel": "Tel",
    "HF.mobile": "Mobile",
    "HF.fax": "Fax",
    "HF.Yahoo": "Yahoo",
    "HF.website": "Website",
    "HF.address": "Address",
    "HF.postalCode": "Postal code",
    "HF.ExistingAccount": "Existing account",
    "HF.DirectLogin": "Direct login",
    "HF.productPrice": "Price",
    "HF.ProductNumber": "Item NO.",
    "HF.Packing": "Packing",
    "HF.OuterBoxCBM": "CBM",
    "HF.OuterBoxCUFT": "CUFT",
    "HF.Innerboxes": "Inner Boxes",
    "HF.OuterQTY": "Outer QTY",
    "HF.GWAndNW": "G.W/N.W",
    "HF.productSize": "Product Size",
    "HF.packageSize": "Package Size",
    "HF.cartonSize": "Carton Size",
    "HF.addCart": "Add To Cart",
    "HF.delCart": "Remove from Cart",
    "HF.product": "Product",
    "HF.NewsUpdates": "News Updates",
    "HF.ReleaseDate":"Release Date",
    "HF.MyCart":"My Cart",
    "HF.ID":"ID",
    "HF.picture":"Picture",
    "HF.ArticleNo":"Item No.",
    "HF.Description":"Product Name",
    "HF.CartOuterQTY":"Outer QTY",
    "HF.CBM":"CBM",
    "HF.CTNS":"CTNS",
    "HF.totalQTY": "Total QTY",
    "HF.TotalVolume":"Total CBM",
    "HF.total":"Total",
    "HF.delete":"Delete",
    "HF.checkAll":"Check All",
    "HF.clear":"Clear",
    "HF.totalItems": "Total Records",
    "HF.totalCTNS": "Total CTNS",
    "HF.noData": "No Data",
    "HF.totalPrice": "Total Price",
    "HF.systemTip": "Tip",
    "HF.confirm": "Confirm",
    "HF.cancel": "Cancel",
    "HF.deleteData": "Do you want to delete the data?",
    "HF.clearCartData": "Do you want to empty your shopping cart?",
    "HF.submitTip": "Confirm to submit the order?",
    "HF.MyOrder": "My Order",
    "HF.PleaseOrderNum": "Please enter the order number",
    "HF.number": "Number",
    "HF.viewDetails": "View Details",
    "HF.orderTime": "Order Time",
    "HF.linkman": "Linkman",
    "HF.sendDate": "Delivery Date",
    "HF.expressNumber": "Tracking Number",
    "HF.orderStatus": "Order Status",
    "HF.unshipped": "Unshipped",
    "HF.shipped": "Shipped",
    "HF.SiteNavigation": "Site Navigation",
    "HF.ContactUs": "Contact us",
    "HF.FollowUs": "Follow us",
    "HF.Landline": "Landline",
    "HF.Email": "E-mail",
    "HF.Phone": "Phone",
    "HF.WeChat": "WeChat",
    "HF.Technic": "Technic Sustain",
    "HF.Software": "HongSheng Soft",
    "HF.detailBtn": "Product details",
    "HF.noDetailBtn": "No product details",
    "HF.classify": "CATALOG",
    "HF.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "HF.loading": "Loading...",
    "HF.updateTime": "UpdateTime",
    "HF.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "HF.MessageTitle": "Submit your requirements online",
    "HF.MessageMsg": "We will reply to your email within 24 hours, except for holidays. We look forward to hearing your voice, helping you solve problems, answering doubts, and exploring cooperation opportunities together. Thank you for choosing us, we will be dedicated to serving you!",
    "HF.MessageName": "name",
    "HF.MessageTel": "YOUR PHONE",
    "HF.MessageEmail": "email",
    "HF.MessageNeeds": "message",
    "HF.SendInquiry": "Send Inquiry",
    "HF.EnquiryList": "ENQUIRY",
    "HF.enquiry": "Enquiry",
    "HF.MyEnquiryList": "My Inquiry Form",
    "HF.Remark": "Remark",
    "HF.Enquirys": "Leave your email and we'll get back to you",
    "HF.proClass": "Product Category",
    "HF.submitOrder": "Confirm submitting the inquiry?",

    "HF.Co-branding": "Co-branding",
    "HF.Co-brandingMsg": "More than 100 companies have conducted in-depth cooperation with our company",
    "HF.recommendPro": "Recommended products",
    "HF.Blog": "Blog",
    "HF.More": "More",
    "HF.nav.Home": "Home",
    "HF.nav.category": "Category",
    "HF.nav.Hot": "Hot Selling",
    "HF.nav.Gift": "Gift",
    "HF.nav.New": "New Product",
    "HF.nav.video": "Video",
    "HF.nav.Blog": "Blog",
    "HF.nav.About": "About Us",
    "HF.nav.contact": "Contact Us",
    "HF.nav.FooterNew": "New Product",
    "HF.news.title": "All Blogs",
    "HF.noMore": "There's no more",
    "HF.top.cart": "Shopping Cart",
    "HF.fomcat.title": "Why choose the Fomcat brand",
    "HF.fomcat.titleMsg": "The advantages are all here! The advantages of Fomcat toy wholesalers cannot be ignored, let's see why we are your best partner!",
    "HF.fomcat1.title1": "Diversified product range",
    "HF.fomcat1.Msg1": "Covering children's toys, remote control toys, models and collectibles, meeting different customer needs. No matter which age group your store is targeting, we have the perfect choice!",
    "HF.fomcat2.title1": "Material quality assurance",
    "HF.fomcat2.Msg1": "We select high-quality materials to create safe and reliable toys for children, undergo market testing, and let parents and children enjoy themselves with peace of mind!",
    "HF.fomcat3.title1": "Competitive wholesale prices",
    "HF.fomcat3.Msg1": "We offer highly competitive wholesale prices, bringing you greater profit margins!",
    "HF.fomcat4.title1": "Easy collaboration",
    "HF.fomcat4.Msg1": "Feel free to contact us and our team will efficiently solve your problems and needs. Our goal is to establish a long-term and successful partnership!",
    "HF.fomcat5.title1": "Convenient ordering",
    "HF.fomcat5.title2": "Intimate service",
    "HF.fomcat5.Mag1": "Your success is our success! Choose the Fomcat brand and work with us to create a beautiful toy paradise!",
    "HF.userGuide": "User's Guide",
    "HF.internationalTransport": "International Transport",
    "HF.cardTitle1": "About QianMeng Technology",
    "HF.cardTitle2": "Logistics and transportation services of QianMeng Technology",
    "HF.cardMsg": "Can represent you in managing the entire logistics process of importing goods from China; It can also completely change the way you purchase and import from China, saving you business time and procurement costs.",
    "HF.card1Title": "Our strengths",
    "HF.card2.Title1": "Logistics Process",
    "HF.card2.Title01": "Factory shipment",
    "HF.card2.Title02": "China's shipment volume",
    "HF.card2.Title03": "Chinese warehouse",
    "HF.card2.Title04": "China Customs",
    "HF.card2.Title05": "International Delivery",
    "HF.card2.Title06": "Global Customs",
    "HF.card2.Title07": "Global domestic transportation",
    "HF.card3.Title": "Logistics services include",
    "HF.card3.msg1": "Provide complete logistics services from the supplier's factory to your shipping address. This allows our customers to monitor the location of their goods during the import process at any time.",
    "HF.card3.msg2": "We have over 70000 square meters of warehouses in China, where we receive, process, and store goods until they are shipped globally.",
    "HF.card3.li": "Receiving goods from the factory",
    "HF.card3.li2": "Storage",
    "HF.card3.li3": "Goods packaging",
    "HF.card3.li4": "Quality control",
    "HF.card3.li5": "Product Production Certificate",
    "HF.card3.li6": "Declare sth. at the customs",
    "HF.card3.li7": "Other services",
    "HF.card4.title": "Information on international transportation and delivery",
    "HF.card4.cell.title": "Estimated cost",
    "HF.card4.cell.msg1": "In order to provide a transportation quotation, we need to know whether the items included in your order are subject to import and export restrictions, whether the factory has inventory, dimensions, and the exact weight of the product.",
    "HF.card4.cell.msg2": "That's why our team needs to contact suppliers to understand this type of information and provide cost estimates.",
    "HF.card4.cell.msg3": "You will receive detailed estimates of transportation costs, cubic volumes, and delivery times for three available modes of transportation (sea freight, air freight, and FedEx).",
    "HF.card4.cell2.title": "Final quotation and transportation",
    "HF.card4.cell2.msg1": "The best transportation quotation can only be obtained after the goods arrive at our warehouse. Therefore, accurate freight quotes and other expenses are calculated after we receive the goods.",
    "HF.card4.cell2.msg2": "Once the items arrive at our warehouse, we will group them into groups to provide the most favorable prices.",
    "HF.card4.cell3.title": "Freight details",
    "HF.card4.cell3.msg1": "International freight.",
    "HF.card4.cell3.msg2": "International shipping costs to the world.",
    "HF.card4.cell3.msg3": "Global domestic logistics costs.",
    "HF.card4.cell3.msg4": "After global customs clearance, we will arrange domestic shipments based on the delivery address.",
    "HF.card4.cell4.title": "Transportation of dangerous goods",
    "HF.card4.cell4.msg1": "Dangerous goods are items with the following characteristics and attributes: threatening human life and health; Causing irreparable damage to the environment; Causing damage to physical objects.",
    "HF.card4.cell4.msg2": "These goods include: animals and animal products, plant products, animal and vegetable fats, food agents, ordinary metals, precision instruments, weapons, ammunition, and artworks.",

    "HF.card4.cell5.title": "Choose an international shipping company",
    "HF.card4.cell5.msg1": "We will choose reliable, fast, and economical international logistics channels for your goods. After careful evaluation, we have comprehensively considered factors such as transportation cost, transportation efficiency, and service stability.",
    "HF.card4.cell5.msg2": "We will continuously strive to find the best logistics partners and transportation routes to serve you on our platform.",

    "HF.card4.cell6.title": "Declare sth. at the customs",
    "HF.card4.cell6.msg1": "The customs clearance will be determined based on the customs clearance method (sea, air, or land) you choose when requesting delivery.",
    "HF.card4.cell6.msg2": "Our company is responsible for handling relevant customs clearance matters. Our company will be responsible for relevant customs clearance matters. Customers can also choose to handle customs clearance procedures themselves.",
    "HF.contact.title": "Your satisfaction is our greatest pursuit. If you have any questions, suggestions or cooperation intentions, please feel free to contact us at any time. We are always ready to provide you with support and assistance.",
    "HF.cacontact.card1.title": "Contact information",
    "HF.cacontact.card1.msg": "Think more for you, do better for you",
    "HF.Official.website": "Official Website",
    "HF.link": "Click on the image to go to the official website",
    "HF.about.subject": "Fomtoy makes imports easier",
    "HF.about.msg": "Fomtoy is an integrated import platform that provides you with tens of thousands of suppliers, enabling you to import products at more competitive prices. We will also manage the entire procurement, logistics, and import process for you.",
    "HF.about.card1.title": "The most powerful B2B e-commerce solution in China",
    "HF.about.card1.content": "Fomtoy is a B2B company whose main goal is to help businesses import products from China in the most cost-effective way, save costs and simplify processes, and provide customers with the best import experience. Our headquarters is located in Suzhou, China, with over 4000 square meters of office operation area and 15000 square meters of warehouse. We have 300 employees and an independent technology research and development team in the group company.",
    "HF.about.card1.label": "Spot Products",
    "HF.about.card1.label2": "Patent",
    "HF.about.card1.label3": "New product",
    "HF.about.card1.label4": "Country",
    "HF.about.card2.title": "Our strengths",
    "HF.about.card2.msg": "We not only dare to promise, but also can do it",
    "HF.about.card2.cell.tit": "Simplify your procurement and imports from China, optimize all costs",
    "HF.about.card2.cell.msg": "The English interface allows you to easily complete the procurement process. Provide quality control, warehousing, international logistics, customs clearance, and additional services. English customer service facilitates communication and after-sales service",
    "HF.about.card2.cell2.tit": "Optimize transportation and import costs",
    "HF.about.card2.cell2.tit2": "The entire process of importing from a single site",
    "HF.about.card2.cell2.tit3": "Warehousing and comprehensive import management",
    "HF.about.card2.cell2.tit4": "Bilingual customer service and after-sales service",
    "HF.about.card2.cell3.tit": "Find everything you want, the world factory is in your hands",
    "HF.about.card2.cell3.msg": "At present, users can find any product from Alibaba, 1688, and other large domestic e-commerce platforms in China on Rakumart. Our extensive cooperation network enables us to provide you with a variety of products and over 100000 high-quality Chinese suppliers.",
    "HF.about.card3.title": "We have clients from various businesses around the world, listen to their evaluations of us",
    "HF.about.card3.msg": "Searching for our location around the world?",
    "HF.about.card3.cell.tit": "The Magic of Fomtoy: The Behind the Scenes of Seamless Order Processing",
    "HF.about.card3.cell.msg": "Before encountering FulfillPanda, I was constantly troubled by the daily order volume. Their seamless order processing system truly changed the game rules, allowing me to focus on my business without worrying about inventory issues.",
    "HF.about.card3.cell2.tit": "3000 orders per day: My Fast Expansion Journey",
    "HF.about.card3.cell2.msg": "My business journey is facing challenges, but over time, with the help of FulfillPanda, I am now processing over 3000 orders per day. This rapid expansion is not just about quantity growth; This proves the hard work of our team with the support of Fomtoy.",
    "HF.about.card3.cell3.tit": "Beyond expectations: FulfillPanda provides more than just services",
    "HF.about.card3.cell3.msg": "In our collaboration with FulfillPanda, I found that they provide more than just one service. They closely monitor my business and provide solutions that exceed the expectations of traditional service providers. This is a sincere and enthusiastic cooperative relationship that goes far beyond the business field",
    "HF.about.card3.cell4.tit": "Fulfillpanda is a lifesaver...",
    "HF.about.card3.cell4.msg": "Even if I have a freight forwarder, it's difficult for me to scale proportionally. Due to running 17 regular stores for different reasons, such as store backup and testing with different advertising accounts. We test 50 products every day to find winners. My team has to occasionally post procurement requests to other platforms. This is very time-consuming. Meanwhile, FulfillPanda will closely monitor your every step, keeping you informed of any information related to inventory issues and providing solutions at any time. Thanks to them, we have to make 3000 orders every day. Most orders are shipped on time. I will definitely recommend FulfillPanda!",
    "HF.about.card4.tit": "Mission",
    "HF.about.card4.msg": "Our mission is to provide high-quality products with outstanding value. We listen to our customers and manufacturers. We observe the world they live in and provide them with new trends in multiple categories of goods. We are passionate about the companies we represent and the trends and innovative products they develop and create. We have been searching for incredible ideas, and we are eager to push these extraordinary new products to every market.",
    "HF.about.card4.subtitle": "01. Our Value",
    "HF.about.card4.cell.tit": "Goal oriented",
    "HF.about.card4.cell.msg": "We are still focused on maintaining profitability growth and expanding our distribution areas for our manufacturers.",
    "HF.about.card4.cell.tit2": "Good faith",
    "HF.about.card4.cell.msg2": "We operate with integrity and high ethical standards, and in everything we strive for.",
    "HF.about.card4.cell.tit3": "Professional ethics",
    "HF.about.card4.cell.msg3": "Diligence and perseverance are fundamental to every aspect of our business. In this challenging era, we work very hard.",
    "HF.about.card4.cell.tit4": "Attention to detail",
    "HF.about.card4.cell.msg4": "We realize that small issues require more attention, and then bigger things will take care of ourselves.",
    "HF.about.card4.subtitle2": "02. visión",
    "HF.about.card4.subtitle2.msg": "Our goal is to become a leader in providing more distribution for manufacturers by listening to the opinions of manufacturers, retailers, and consumers. Profit and structure in a high organizational system will create success. We strive to motivate our customer base with products they don't know about, they never know they need or exist. However, once they develop a liking for our products, they cannot understand their existence without them.",
    "HF.about.card5.Company": "Company headquarters",
    "HF.about.card5.info": "Suzhou, China",
    "HF.about.card5.address": "Company address",
    "HF.about.card5.addressName": "18th Floor, Office Building 1818, Industrial Park, Suzhou City, Jiangsu Province",
    "HF.about.card5.Telephone": "Telephone",
    "HF.Hi": "Hi~",
    "HF.companyProfile": "Company Profile",
    "HF.stainlessSteelProductFromHufa": 'Stainless Steel Product From Hufa',
    "HF.hufaFactoryWorkshop": 'Hufa Factory Workshop',
    "HF.showroom": 'HUFA SHOWROOM',
    "HF.hufaExhibition":'Hufa Exhibition',
    "HF.customerWall": 'Customer Wall',
    "HF.shippingMoment": 'Shipping Moment',
    "HF.ourCertificate": 'Our Certificate',
    "HF.ourCertificate.text": "Quality is our commitment. Every kitchen utensil in our inventory is carefully crafted to meet the highest safety and durability standards. Don't worry, I know your smile will last.",
    "HF.qq": "QQ",
    "HF.whatsapp": "Whatsapp",
    "HF.wechat": "Wechat",
    "HF.outerBox": "Outer box",
    "HF.innerBox": 'Inner box',
    "HF.moreBlogs": 'More blogs',
    "HF.corporateName": 'Chaozhou Chaoan Caitang Hufa Stainless Steel Factory',
    "HF.totalQuantity": "Total Quantity",
    "HF.showroom.title": "Factory Exhibition Hall",
    "HF.showroom.item1": "Very big showroom 600㎡，with over 3000 kinds of products exhibited",
    "HF.showroom.item2": "Over 50pcs of automatic production machines",
    "HF.showroom.item3": "With the production acrea of 9800 ㎡",
    "HF.showroom.item4": "With exclusive patents home and abroad",
    "HF.showroom.item5": "Welcome OEM and ODM",
    "HF.showroom.item6": "Factory address: ",
    "HF.exhibition.title": "YiWu Exhibition Hall",
    "HF.exhibition.item1": "Yiwu top 3 kitchenware showroom",
    "HF.exhibition.item2": "More than 3,000 kinds of hotel supplies and household appliances",
    "HF.exhibition.item3": "The best & biggest team in Yiwu international City ",
    "HF.exhibition.item4": "Ready professional quotation lists for over 50 different series of kitchenware",
    "HF.exhibition.item5": "Offer the best prices with the most efficient services",
    "HF.exhibition.item6": "Address: shop 19070, 22nd door, 4th floor of Yiwu International City",
    "HF.contactUs.questions": "We are committed to providing you with personalized service. Please contact us for any questions",
    "HF.contactUs.ideas": "Tell us about your project ideas or just say hello. Whether you've got a big idea or need some inspiration with a building proiect, we are here to create perfect buildings, From concept to creation, let us inspire you.",
    "HF.contactUs.work": "We'd like to work with you",
    "HF.contactUs.quotationAccordingly": "Please select the items that you want and put them into the cart and then contact us.Or you could leave your name and email in the landing page,and then we could send to you the quotation accordingly.",
    "HF.submitMessage": "Submit Message",
    "HF.QTY":"QTY"
}   
