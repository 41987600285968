<!--
 * @Description: 首页
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: xie
 * @LastEditTime: 2024-07-31 14:53:19
-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'homePage',
  mixins: [mixins],

  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageHomeOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageHomeTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageHomeThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageHomeFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageHomeFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageHomeSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageHomeSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'pageHomeEight'
          break
        case 'themeTemplateNine':
          this.componentName = 'pageHomeNine'
          break
        case 'themeTemplateTen':
          this.componentName = 'pageHomeTen'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>