/*
 * @Description: 锦泰国际英文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-05-23 17:58:34
 */
export default {
    "JT.navTitle.Home":"Home",
    "JT.navTitle.Produch":"Product",
    "JT.navTitle.Cart":"Shopping Cart",
    "JT.navTitle.Exhibition":"Exhibition Plan",
    "JT.navTitle.Message":"Contact / About Us",
    "JT.logoBox.placeholder":"Please enter the product keyword",
    "JT.ProductNumber":"Item NO.",
    "JT.amount":"Quantity",
    "JT.contactUs":"Contact Us",
    "JT.understandDeke":"About Us",
    "JT.telephone":"Mob",
    "JT.Tel": "Tel",
    "JT.phoneAndWeixin":"Phone & Wechat",
    "JT.PleaseOrderNum":"Please enter the order number",
    "JT.ArticleNo":"Item No.",
    "JT.TotalVolume":"Total CBM",
    "JT.totalQTY": "Total QTY",
    "JT.totalItems": "Total Items",
    "JT.ExhibitionRelatedProducts":"Exhibition related products",
    "JT.ProductsVideo":"Products Video",
    "JT.ReleaseDate":"Release Date",
    "JT.inquirySheet":"Inquiry List",
    "JT.SendInquiry":"Add to inquiry",
    "JT.productPrice":"Price",
    "JT.OuterBoxCBM":"CBM",
    "JT.OuterBoxCUFT":"CUFT",
    "JT.Innerboxes":"Inner Boxes",
    "JT.OuterCapacity":"Outer QTY",
    "JT.productDetails":"Product details",
    "JT.similarProduct":"Similar product",
    "JT.MyRFQ":"My RFQ",
    "JT.enquiry":"Enquiry",
    "JT.enquirys": "Leave your email and we'll get back to you",
    "JT.contact":"Contact Person",
    "JT.Description":"Description",
    "JT.OuterQTY":"Outer QTY",
    "JT.LastSevenDays": "Seven Days",
    "JT.All": "All",
    "JT.PleaseEmial": "Please enter your email",
    "JT.PleaseCode": "Please enter the vailde code",
    "JT.PleasePhone": "Please enter your mobile phone number",
    "JT.PleaseAccount": "Please enter your username",
    "JT.userName": "Username",
    "JT.PleaseAddress": "Please enter your contact address",
    "JT.PleasePassword": "Please enter your password",
    "JT.PleaseName": "Please enter your name",
    "JT.CompanyProfile": "Company Profile",
    "JT.ContactPerson": "Contact Person",
    "JT.Emial": "E-mial",
    "JT.enterSearch": "Please enter keywords",
    "JT.CompanyDescription": "21 years focus on children's toy enterprises",
    "JT.viewMore":"view more",
    "JT.Cart": "Cart",
    "JT.Service": "Service",
    "JT.WeChat": "WeChat",
    "JT.Top": "Top",
    "JT.LastSevenDays": "Seven Days",
}   
