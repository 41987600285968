<!--
 * @Description: 新产品页
 * @Author: zml
 * @Date: 2023-09-14 18:01:55
 * @LastEditors: zml
 * @LastEditTime: 2023-11-08 10:43:56
-->

<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'productPage',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        // case 'themeTemplateOne':
        //   this.componentName = 'pageProductOne'
        //   break
        // case 'themeTemplateTow':
        //   this.componentName = 'pageProductTow'
        //   break
        // case 'themeTemplateThree':
        //   this.componentName = 'pageProductThree'
        //   break
        // case 'themeTemplateFour':
        //   this.componentName = 'pageProductFour'
        //   break
        // case 'themeTemplateFive':
        //   this.componentName = 'pageProductFive'
        //   break
        case 'themeTemplateSix':
          this.componentName = 'pageNewProSix'
          break
        // case 'themeTemplateSeven':
        //   this.componentName = 'pageProductSeven'
        //   break
        // case 'themeTemplateEight':
        //   this.componentName = 'pageHotproductEight'
        //   break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>