/*
 * @Description: 凯荣丰俄文
 * @Author: zml
 * @Date: 2023-04-17 11:52:16
 * @LastEditors: zml
 * @LastEditTime: 2023-06-15 09:37:03
 */
export default {
    "KRF.navTitle.Home":"Главная  сайта",
    "KRF.navTitle.Produch":"продукции Показать",
    "KRF.navTitle.Cart":"Тележка покупок",
    "KRF.navTitle.Exhibition":"План выставок",
    "KRF.navTitle.Message":"Связаться / О нас",
    "KRF.logoBox.placeholder":"Введите ключевые слова продук",
    "KRF.ProductNumber":"Номер продукта",
    "KRF.amount":"Количество",
    "KRF.contactUs":"Свяжитесь с нами",
    "KRF.understandDeke":"Понимание Деко",
    "KRF.telephone":"Самолёт",
    "KRF.phoneAndWeixin":"Мобильный микрофон",
    "KRF.PleaseOrderNum":"Введите номер заказа.",
    "KRF.ArticleNo":"Номер",
    "KRF.TotalVolume":"Общий объем",
    "KRF.totalQTY": "总数量",
    "KRF.totalItems": "总款数",
    "KRF.ExhibitionRelatedProducts":"Выставка ассоциированных продуктов",
    "KRF.ProductsVideo":"Видео продукции",
    "KRF.ReleaseDate":"Дата выпуска",
    "KRF.inquirySheet":"Запрос",
    "KRF.SendInquiry":"Запрос",
    "KRF.productPrice":"Цены продукцию",
    "KRF.OuterBoxCBM":"外箱材积",
    "KRF.OuterBoxCUFT":"外箱体积",
    "KRF.Innerboxes":"Количество ячеек",
    "KRF.OuterCapacity":"Загрузка наружного ящика",
    "KRF.productDetails":"Подробности продукции",
    "KRF.similarProduct":"Похожие продукты",
    "KRF.MyRFQ":"Мой запрос.",
    "KRF.enquiry":"Запрос цены",
    "KRF.enquirys": "请留下您的电子邮件，我们会与您联系",
    "KRF.contact":"Контактная",
    "KRF.Description":"Имя",
    "KRF.OuterQTY":"外箱装量",
    "KRF.LastSevenDays": "最近七天",
    "KRF.All": "全部",
    "KRF.PleaseEmial": "请输入邮箱",
    "KRF.PleaseCode": "请输入验证码",
    "KRF.PleasePhone": "请输入手机号",
    "KRF.PleaseName": "请输入用户名",
    "KRF.userName": "用户名",
    "KRF.PleaseAddress": "请输入联系地址",


    "KRF.loginTitle": "Пожалуйста, войдите",
    "KRF.login": "Регистрация",
    "KRF.PleaseAccount": "Пожалуйста, введите свой аккаунт",
    "KRF.PleasesAccount": "Пожалуйста, введите свой аккаунт",
    "KRF.passwordEmpty": "Пароль не может быть пустым",
    "KRF.Password": "Пароль",
    "KRF.PleasePassword": "请输入Пароль",
    "KRF.news": "Новый",
    "KRF.Video": "Видео",
    "KRF.Search": "Поиск",
    "KRF.LearnMore": "Узнать больше",
    "KRF.comprehensiveInquiry": "Узнать больше",
    "KRF.AdvancedSearch": "Advanced Search",
    "KRF.close": "коллапс",
    "KRF.open": "раскат",
    "KRF.Emial": "Emial",
    "KRF.Tel": "Tel",
    "KRF.WeChat": "WeChat",
    "KRF.updateData": "Update Data",
    "KRF.confirm": "confirm",
    "KRF.updateSuccessfully": "updateSuccessfully",
    "KRF.UpdateFailure": "UpdateFailure",
    "KRF.Logout": "Logout",
    "KRF.MyOrders": "My Order",
    "KRF.Cart": "Cart",
    "KRF.Hello": "Hello",
    "KRF.hotProduct": "热销产品",
    "KRF.Country": "Country",
    "KRF.PleaseCountry": "Please enter country",
    "KRF.BrandZone": "品牌专区",
    "KRF.ViewMore": "查看更多",
}   
