import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=041ea231&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041ea231",
  null
  
)

export default component.exports