<!--
 * @Description: 新闻详情
 * @Author: zml
 * @Date: 2023-04-13 18:05:47
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:21:56
-->

<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'newsDetailPage',
  mixins: [mixins],

  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateFour':
          this.componentName = 'pageNewsDetailFour'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageNewsDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageNewsDetailSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'pageNewsDetailEight'
          break
        case 'themeTemplateTen':
          this.componentName = 'pageNewsDetailTen'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>