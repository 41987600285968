/*
 * @Description: 多明戈中文
 * @Author: zml
 * @Date: 2023-09-14 18:01:55
 * @LastEditors: zml
 * @LastEditTime: 2023-09-27 09:26:40
 */

export default {
    "DMG.login": "登录",
    "DMG.register": "注册",
    "DMG.logout": "退出",
    "DMG.MyOrder": "我的订单",
    "DMG.logoBox.placeholder": "关键字",
    "DMG.logoBox.search": "搜索",
    "DMG.logoBox.AdvancedSearch": "高级搜索",
    "DMG.nav.Home": "首页",
    "DMG.nav.Products": "产品展示",
    "DMG.nav.About": "关于我们",
    "DMG.nav.contact": "联系我们",
    "DMG.nav.news": "新闻",
    "DMG.nav.cart": "购物车",
    "DMG.ViewPrice": "查看价格",
    "DMG.SameFactory": "同一个工厂",
    "DMG.newPro": "最新产品",
    "DMG.AnotherBatch": "换一批",
    "DMG.ViewMore": "查看更多",
    "DMG.hotPro": "热销产品",
    "DMG.hotVidio": "热点视频",
    "DMG.proUnBoxVidio": "开箱视频",
    "DMG.Search": "搜索",
    "DMG.Reset": "重置",
    "DMG.Description": "产品名称 （ 产品名称的任何部分 ）",
    "DMG.ItemNO": "项目编号",
    "DMG.MTCode": "公司编号",
    "DMG.FTYCode": "出厂货号",
    "DMG.Kind": "种类",
    "DMG.Size": "产品规格",
    "DMG.Price": "价格",
    "DMG.Date": "日期",
    "DMG.Sort": "排序",
    "DMG.DateDesc": "日期降序",
    "DMG.DateAsc": "日期升序",
    "DMG.PriceDesc": "价格降序",
    "DMG.PriceAsc": "价格升序",
    "DMG.NO.Desc": "公司编号降序",
    "DMG.NO.Asc": "公司编号升序",
    "DMG.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "DMG.PleaseSelect": "请选择",
    "DMG.PleaseLength": "长",
    "DMG.PleaseWidth": "宽",
    "DMG.PleaseHeight": "高",
    "DMG.PleaseMinPrice": "最小价格",
    "DMG.PleaseMaxPrice": "最大价格",
    "DMG.PleaseStartDate": "开始日期",
    "DMG.PleaseEndDate": "结束日期",
    "DMG.UserAccount": "用户名",
    "DMG.password": "密码",
    "DMG.GROUPSEARCH": "综合搜索",
    "DMG.Video_playback": "视频播放",
    "DMG.loginMsg": "访问您的帐户和订单历史记录",
    "DMG.onUserAccount": "没有账号",
    "DMG.SignUp": "立即注册",
    "DMG.ForgetPassword": "忘记密码",
    "DMG.resetPasswords": "重置密码",
    "DMG.emailAddress": "电子邮箱",
    "DMG.verifyCode": "验证码",
    "DMG.getVerifyCode": "获取验证码",
    "DMG.newPassword": "新密码",
    "DMG.confirmPassword": "确认密码",
    "DMG.Submit": "提交",
    "DMG.SignUpAccount": "注册",
    "DMG.LoginOnly": "仅用于登录",
    "DMG.Name": "姓名",
    "DMG.NameMsg": "请输入您的姓名，2~30个字符",
    "DMG.passwordMsg": "至少6个字符",
    "DMG.requiredFields": "*必填项",
    "DMG.registerEmail": "注册邮箱",
    "DMG.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "DMG.gender": "性别",
    "DMG.man": "男",
    "DMG.woman": "女",
    "DMG.customerSource": "客户来源",
    "DMG.salesman": "销售员",
    "DMG.Country": "国家",
    "DMG.companyName": "公司名称",
    "DMG.duty": "职务",
    "DMG.tel": "联系电话",
    "DMG.mobile": "手机号",
    "DMG.fax": "传真",
    "DMG.Yahoo": "雅虎",
    "DMG.website": "网站",
    "DMG.address": "联系地址",
    "DMG.postalCode": "邮政编码",
    "DMG.ExistingAccount": "已有账号",
    "DMG.DirectLogin": "直接登录",
    "DMG.productPrice": "产品价格",
    "DMG.ProductNumber": "产品编号",
    "DMG.Packing": "包装方式",
    "DMG.OuterBoxCBM": "外箱体积",
    "DMG.OuterBoxCUFT": "外箱材积",
    "DMG.Innerboxes": "内盒个数",
    "DMG.OuterQTY": "外箱装量",
    "DMG.GWAndNW": "毛重 / 净重",
    "DMG.productSize": "产品规格",
    "DMG.packageSize": "包装规格",
    "DMG.cartonSize": "外箱规格",
    "DMG.addCart": "添加购物车",
    "DMG.delCart": "取消加购",
    "DMG.product": "产品",
    "DMG.NewsUpdates": "新闻动态",
    "DMG.ReleaseDate": "发布日期",
    "DMG.MyCart": "我的购物车",
    "DMG.ID": "ID",
    "DMG.picture": "图片",
    "DMG.ArticleNo": "货号",
    "DMG.Description": "名称",
    "DMG.CartOuterQTY": "外箱装量",
    "DMG.CBM": "体积",
    "DMG.CTNS": "箱数",
    "DMG.totalQTY": "总数量",
    "DMG.TotalVolume": "总体积",
    "DMG.total": "合计",
    "DMG.delete": "删除",
    "DMG.checkAll": "全选",
    "DMG.clear": "清空购物车",
    "DMG.totalItems": "总款数",
    "DMG.totalCTNS": "总箱量",
    "DMG.noData": "暂无数据",
    "DMG.totalPrice": "总价",
    "DMG.systemTip": "系统提示",
    "DMG.confirm": "确定",
    "DMG.cancel": "取消",
    "DMG.deleteData": "是否删除该条数据？",
    "DMG.clearCartData": "是否清空全部购物车？",
    "DMG.submitTip": "确认提交订单？",
    "DMG.MyOrder": "我的订单",
    "DMG.PleaseOrderNum": "请输入订单号",
    "DMG.number": "单号",
    "DMG.viewDetails": "查看详情",
    "DMG.orderTime": "订单时间",
    "DMG.linkman": "联系人",
    "DMG.sendDate": "发货时间",
    "DMG.expressNumber": "快递单号",
    "DMG.orderStatus": "订单状态",
    "DMG.unshipped": "未发货",
    "DMG.shipped": "已发货",
    "DMG.SiteNavigation": "网站导航",
    "DMG.ContactUs": "联系我们",
    "DMG.FollowUs": "关注我们",
    "DMG.Landline": "座机",
    "DMG.Email": "邮箱",
    "DMG.Phone": "手机号",
    "DMG.WeChat": "微信号",
    "DMG.Technic": "技术支持",
    "DMG.Software": "深圳宏升软件技术开发有限公司",
    "DMG.detailBtn": "产品详情",
    "DMG.noDetailBtn": "暂无产品详情",
    "DMG.classify": "产品分类",
    "DMG.exclusive": "专属推荐",
    "DMG.loading": "加载中...",
    "DMG.updateTime": "更新时间",
    "DMG.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "DMG.MessageTitle": "在线提交您的需求",
    "DMG.MessageMsg": "我们将在24小时内回复您的电子邮件，节假日除外",
    "DMG.MessageName": "你的名字",
    "DMG.MessageTel": "你的手机",
    "DMG.MessageEmail": "你的邮箱",
    "DMG.MessageNeeds": "你的需求",
    "DMG.SendInquiry": "发询价单",
    "DMG.EnquiryList": "询价单",
    "DMG.enquiry": "询价",
    "DMG.MyEnquiryList": "我的询价单",
    "DMG.Remark": "备注",
    "DMG.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "DMG.proClass": "产品分类",
    "DMG.title": "欢迎来到厦门多明戈贸易有限公司官网!",
    "DMG.userAccount":"用户名",
    "DMG.CompanyProfile":"公司简介",
    "DMG.Exhibition":"展会信息",
    "DMG.More":"更多",
    "DMG.Hotsales":"热销产品",
    "DMG.Comprehensivequery":"综合查询",
    "DMG.Productrype": "产品种类",
    "DMG.Producprice": "产品价格",
    "DMG.sortorder": "排序方式",
    "DMG.similarproduct":"相似产品",
    "DMG.advancedsearch":"高级搜索",
    "DMG.Factorymodel":"工厂型号",
    "DMG.Productsize":"产品尺寸",
    "DMG.SerialNumber":"序号",
    "DMG.identifier":"编号",
    "DMG.volume":"材积",
    "DMG.package":"包装",
    "DMG.Directlogin":"直接登录",
    "DMG.Sendverificationcode":"发送验证码",
    "DMG.confirmnewPassword": "确认新密码",
    "DMG.PackageNo":"包装编号",
    "DMG.loginSuccess":"登录成功",
    "DMG.regSuccess":"注册成功",
    "DMG.resetsuccessful":"重置成功",
    "DMG.ProName":"产品名称",
    "DMG.Disclaimer": "免责声明:本网站所有图片和产品均来自厂商，版权归厂商所有。",
}
