/*
 * @Description: 互发俄语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: xie
 * @LastEditTime: 2024-08-16 14:24:03
 */
export default {
    "HF.login":"Регистрация",
    "HF.register":"Регистрация",
    "HF.logout":"Списание",
    "HF.MyOrder":"Мой заказ.",
    "HF.logoBox.placeholder": "Введите ключевое слово",
    "HF.logoBox.search": "Поиск",
    "HF.logoBox.AdvancedSearch": "Расширенный поиск",
    "HF.nav.Products": "Продукты",
    "HF.nav.news": "Информация для прессы",
    "HF.ViewPrice": "Посмотреть цены",
    "HF.SameFactory": "На том же заводе",
    "HF.newPro": "Новичок",
    "HF.AnotherBatch": "Другая партия",
    "HF.ViewMore": "Узнать больше",
    "HF.hotPro": "Горячие товары",
    "HF.hotVidio": "Популярное видео по запросу",
    "HF.proUnBoxVidio": "Изделие разобрано видео",
    "HF.Search": "Поиск",
    "HF.Reset": "Сбросить",
    "HF.ItemNO": "Номер проекта.",
    "HF.MTCode": "Код MT",
    "HF.FTYCode": "Код FTY",
    "HF.Kind": "Вид",
    "HF.Size": "Размер",
    "HF.Price": "Цены",
    "HF.Date": "Дата",
    "HF.Sort": "Сортировка",
    "HF.DateDesc": "Описание даты",
    "HF.DateAsc": "Дата ASC",
    "HF.PriceDesc": "Снижение цен",
    "HF.PriceAsc": "Цены ASC",
    "HF.NO.Desc": "Нет.",
    "HF.NO.Asc": "Нет ASC",
    "HF.PleaseEnterTheProductNameKeyword": "Введите ключевые слова названия продукта",
    "HF.PleaseSelect": "Выберите",
    "HF.PleaseLength": "Длина",
    "HF.PleaseWidth": "Ширина",
    "HF.PleaseHeight": "Рост",
    "HF.PleaseMinPrice": "Минимальная цена",
    "HF.PleaseMaxPrice": "Максимальная цена",
    "HF.PleaseStartDate": "Дата начала",
    "HF.PleaseEndDate": "Дата окончания",
    "HF.UserAccount": "Пользовательский аккаунт",
    "HF.password": "Пароль",
    "HF.GROUPSEARCH": "Групповой поиск",
    "HF.Video_playback": "Видеопроизводство",
    "HF.loginMsg": "Доступ к вашей учетной записи и истории заказов",
    "HF.onUserAccount": "Нет счета.",
    "HF.SignUp": "Регистрация",
    "HF.ForgetPassword": "Забыть пароль",
    "HF.resetPasswords": "Сбросить пароль",
    "HF.emailAddress": "Адрес электронной почты",
    "HF.verifyCode": "Код проверки",
    "HF.getVerifyCode": "Получение кода",
    "HF.newPassword": "Новый пароль",
    "HF.confirmPassword": "Подтвердить пароль",
    "HF.Submit": "Представлено",
    "HF.SignUpAccount": "Регистрация",
    "HF.LoginOnly": "Только регистрация",
    "HF.Name": "Имя",
    "HF.NameMsg": "Введите свое имя, от 2 до 30 символов.",
    "HF.passwordMsg": "Не менее 6 символов",
    "HF.requiredFields": "* Обязательное поле",
    "HF.Email": "Электронная почта",
    "HF.EmailMsg": "Пожалуйста, введите электронную почту. Для регистрации",
    "HF.gender": "Пол",
    "HF.man": "Гн Мохаммед Саид.",
    "HF.woman": "Госпожа...",
    "HF.customerSource": "Источник клиента",
    "HF.salesman": "Связаться с продавцом",
    "HF.Country": "Страна",
    "HF.Company": "Единицы измерения",
    "HF.duty": "Название работы",
    "HF.tel": "Телефон",
    "HF.mobile": "Мобильные телефоны",
    "HF.fax": "Факс",
    "HF.Yahoo": "Яхо",
    "HF.website": "Веб - сайт",
    "HF.address": "Адрес",
    "HF.postalCode": "Почтовый индекс",
    "HF.ExistingAccount": "Существующие счета",
    "HF.DirectLogin": "Прямой вход",
    "HF.productPrice": "Цены",
    "HF.ProductNumber": "Номер проекта.",
    "HF.Packing": "Упаковка",
    "HF.OuterBoxCBM": "КБМ",
    "HF.OuterBoxCUFT": "КУФТ",
    "HF.Innerboxes": "Внутренний ящик",
    "HF.OuterQTY": "Внешнее число",
    "HF.GWAndNW": "G.W / N.W",
    "HF.productSize": "Размеры продукции",
    "HF.packageSize": "Размер упаковки",
    "HF.cartonSize": "Спецификация наружного ящика",
    "HF.addCart": "Добавить в корзину",
    "HF.delCart": "Удалить из корзины",
    "HF.product": "Продуктовый центр",
    "HF.NewsUpdates": "События в области общественной информации",
    "HF.ReleaseDate":"Дата выпуска",
    "HF.MyCart":"Моя тележка.",
    "HF.ID":"Удостоверения личности",
    "HF.picture":"Фотографии",
    "HF.ArticleNo":"Номер проекта.",
    "HF.Description":"Наименование",
    "HF.CartOuterQTY":"Внешнее число",
    "HF.CBM":"КБМ",
    "HF.CTNS":"КТНС",
    "HF.totalQTY": "Всего",
    "HF.TotalVolume":"Итого, CBM",
    "HF.total":"Всего",
    "HF.delete":"Удалить",
    "HF.checkAll":"Все проверки",
    "HF.clear":"Очистить",
    "HF.totalItems": "Общее число проектов",
    "HF.totalCTNS": "Итого CTNS",
    "HF.noData": "Данные отсутствуют",
    "HF.totalPrice": "Общая стоимость",
    "HF.systemTip": "Советы",
    "HF.confirm": "Подтверждение",
    "HF.cancel": "Отменить",
    "HF.deleteData": "Хотите удалить данные?",
    "HF.clearCartData": "Хотите очистить корзину?",
    "HF.submitTip": "Подтвердить доставку заказа?",
    "HF.MyOrder": "Мой заказ.",
    "HF.PleaseOrderNum": "Введите номер заказа.",
    "HF.number": "Номер",
    "HF.viewDetails": "Смотрите подробную информацию",
    "HF.orderTime": "Время создания",
    "HF.linkman": "Линкман",
    "HF.sendDate": "Дата поставки",
    "HF.expressNumber": "Номер запроса",
    "HF.orderStatus": "Статус заказа",
    "HF.unshipped": "Не отгружено",
    "HF.shipped": "Погрузка",
    "HF.SiteNavigation": "Навигация по сайту",
    "HF.ContactUs": "Свяжитесь с нами",
    "HF.FollowUs": "Заботьтесь о нас",
    "HF.Landline": "Стационарные телефоны",
    "HF.Email": "Электронная почта",
    "HF.Phone": "Телефон",
    "HF.WeChat": "Микросхема",
    "HF.Technic": "Техническая поддержка",
    "HF.Software": "Большой и мягкий",
    "HF.detailBtn": "Подробности продукции",
    "HF.noDetailBtn": "Нет подробной информации о продукте",
    "HF.classify": "Содержание",
    "HF.exclusive": "Эксклюзивная рекомендация",
    "HF.loading": "Загрузить...",
    "HF.updateTime": "Время обновления",
    "HF.SearchMsg": "Условия поиска могут быть одним или несколькими комплексными поисками.",
    "HF.MessageTitle": "Отправьте свои потребности онлайн.",
    "HF.MessageMsg": "Мы ответим на вашу электронную почту в течение 24 часов, за исключением праздников. Мы с нетерпением ждем услышать ваш голос, чтобы помочь вам решить проблемы, ответить на вопросы и совместно изучить возможности сотрудничества. Спасибо, что выбрали нас, и мы будем к вашим услугам!",
    "HF.MessageName": "Имя",
    "HF.MessageTel": "Твой телефон.",
    "HF.MessageEmail": "Электронная почта",
    "HF.MessageNeeds": "Сообщение",
    "HF.SendInquiry": "Отправить запрос",
    "HF.EnquiryList": "Запрос",
    "HF.enquiry": "Запрос",
    "HF.MyEnquiryList": "Моя консультационная форма.",
    "HF.Remark": "Примечания",
    "HF.Enquirys": "Оставьте свой e - mail, и мы ответим вам как можно скорее.",
    "HF.proClass": "Категория продукции",
    "HF.submitOrder": "Подтвердить отправку запроса?",
    "HF.Co-branding": "Объединённая марка",
    "HF.Co-brandingMsg": "Более 100 компаний тесно сотрудничают с нашей компанией.",
    "HF.recommendPro": "Рекомендуемые продукты",
    "HF.Blog": "Блог",
    "HF.More": "Больше.",
    "HF.nav.Home": "Домой",
    "HF.nav.category": "Категория",
    "HF.nav.Hot": "Горячие продажи",
    "HF.nav.Gift": "Подарок",
    "HF.nav.New": "Новые продукты",
    "HF.nav.video": "Видео",
    "HF.nav.Blog": "Блог",
    "HF.nav.About": "О нас",
    "HF.nav.contact": "Свяжитесь с нами",
    "HF.nav.FooterNew": "Новые продукты",
    "HF.news.title": "Все блоги",
    "HF.noMore": "Нет.",
    "HF.top.cart": "Тележка для покупок",
    "HF.fomcat.title": "Почему стоит выбрать марку Fomcat",
    "HF.fomcat.titleMsg": "Преимущества здесь! Преимущества оптовых торговцев игрушками Fomcat нельзя игнорировать, давайте посмотрим, почему мы ваши лучшие партнеры!",
    "HF.fomcat1.title1": "Диверсифицированный ассортимент продукции",
    "HF.fomcat1.Msg1": "Охватывает детские игрушки, дистанционно управляемые игрушки, модели и коллекции для удовлетворения потребностей различных клиентов. Независимо от возраста вашего магазина, у нас есть идеальный выбор!",
    "HF.fomcat2.title1": "Обеспечение качества материалов",
    "HF.fomcat2.Msg1": "Мы выбираем качественные материалы для создания безопасных и надежных игрушек для детей, после тестирования рынка, чтобы родители и дети могли спокойно наслаждаться!",
    "HF.fomcat3.title1": "Конкурентоспособные оптовые цены",
    "HF.fomcat3.Msg1": "Мы предлагаем очень конкурентоспособные оптовые цены, которые принесут вам большую прибыль!",
    "HF.fomcat4.title1": "Легко сотрудничать",
    "HF.fomcat4.Msg1": "Пожалуйста, свяжитесь с нами в любое время, и наша команда будет эффективно решать ваши проблемы и потребности. Наша цель - построить долгосрочное и успешное партнерство!",
    "HF.fomcat5.title1": "Удобный заказ",
    "HF.fomcat5.title2": "Услуги",
    "HF.fomcat5.Mag1": "Ваш успех - это наш успех! Выберите бренд Fomcat и создайте с нами прекрасный рай для игрушек!",
    "HF.userGuide": "Руководство пользователя",
    "HF.internationalTransport": "Международные перевозки",
    "HF.cardTitle1": "О тысячелетии науки",
    "HF.cardTitle2": "Технологические логистические транспортные услуги",
    "HF.cardMsg": "От вашего имени вы можете управлять всем логистическим процессом импорта товаров из Китая; Это также может полностью изменить способ покупки и импорта из Китая, экономя время работы и расходы на покупку.",
    "HF.card1Title": "Наше преимущество",
    "HF.card2.Title1": "Логистический процесс",
    "HF.card2.Title01": "Заводские перевозки",
    "HF.card2.Title02": "Поставки из Китая",
    "HF.card2.Title03": "Китайский склад",
    "HF.card2.Title04": "Китайская таможня",
    "HF.card2.Title05": "Международное распределение",
    "HF.card2.Title06": "Глобальная таможня",
    "HF.card2.Title07": "Глобальный внутренний транспорт",
    "HF.card3.Title": "Логистические услуги включают",
    "HF.card3.msg1": "Предоставьте полный логистический сервис от завода поставщика до вашего адреса получения. Это позволяет нашим клиентам контролировать местоположение своих товаров в любое время в процессе импорта.",
    "HF.card3.msg2": "У нас есть более 70 000 квадратных метров складов в Китае, где мы получаем, обрабатываем и храним товары до тех пор, пока они не будут отправлены по всему миру.",
    "HF.card3.li": "Получение товаров с завода",
    "HF.card3.li2": "Депозитарий",
    "HF.card3.li3": "Упаковка грузов",
    "HF.card3.li4": "Контроль качества",
    "HF.card3.li5": "Сертификат на производство продукции",
    "HF.card3.li6": "Что - то декларировать на таможне",
    "HF.card3.li7": "Прочие услуги",
    "HF.card4.title": "Информация о международных перевозках и поставках",
    "HF.card4.cell.title": "Смета расходов",
    "HF.card4.cell.msg1": "Чтобы предоставить предложение на перевозку, нам нужно знать, подпадают ли предметы, включенные в ваш заказ, под импортные и экспортные ограничения, есть ли на заводе запасы, размеры и точный вес продукции.",
    "HF.card4.cell.msg2": "Вот почему наша команда должна связаться с поставщиками, чтобы узнать эту информацию и предоставить смету расходов.",
    "HF.card4.cell.msg3": "Вы получите подробную оценку стоимости транспортировки, кубического объема и времени доставки для трех доступных видов транспорта (морской, воздушный и FedEx).",
    "HF.card4.cell2.title": "Окончательное предложение и перевозка",
    "HF.card4.cell2.msg1": "Оптимальное транспортное предложение можно получить только после того, как товар прибывает на наш склад. Поэтому точная цена фрахта и другие расходы рассчитываются после получения нами груза.",
    "HF.card4.cell2.msg2": "Как только товары поступят на наш склад, мы сгруппироваем их, чтобы предложить наиболее выгодные цены.",
    "HF.card4.cell3.title": "Подробная разбивка расходов на перевозку",
    "HF.card4.cell3.msg1": "Международные грузовые перевозки.",
    "HF.card4.cell3.msg2": "Международные транспортные расходы в мире.",
    "HF.card4.cell3.msg3": "Глобальные внутренние логистические издержки.",
    "HF.card4.cell3.msg4": "После глобальной таможенной очистки мы организуем внутреннюю доставку в соответствии с адресом доставки.",
    "HF.card4.cell4.title": "Перевозка опасных грузов",
    "HF.card4.cell4.msg1": "Под опасными грузами понимаются изделия, обладающие следующими характеристиками и свойствами: представляющие угрозу для жизни и здоровья человека; причинение непоправимого ущерба окружающей среде; Повреждение физических объектов.",
    "HF.card4.cell4.msg2": "Эти товары включают: продукты животного и животного происхождения, растительные продукты, жиры животного и растительного происхождения, пищевые агенты, обычные металлы, прецизионные приборы, оружие, боеприпасы и произведения искусства.",
    "HF.card4.cell5.title": "Выбрать международную судоходную компанию",
    "HF.card4.cell5.msg1": "Мы выберем надежный, быстрый и экономичный международный логистический канал для вашего груза. После тщательной оценки мы рассмотрели такие факторы, как транспортные расходы, эффективность перевозок и стабильность обслуживания.",
    "HF.card4.cell5.msg2": "Мы будем постоянно стремиться к поиску лучших логистических партнеров и транспортных маршрутов для вас на нашей платформе.",
    "HF.card4.cell6.title": "Что - то декларировать на таможне",
    "HF.card4.cell6.msg1": "Таможенная очистка будет определяться в зависимости от способа таможенной очистки (морской, воздушный или наземный), который вы выберете при запросе поставки.",
    "HF.card4.cell6.msg2": "Наша компания отвечает за соответствующие вопросы таможенной очистки. Наша компания будет отвечать за соответствующую таможенную очистку. Клиент также может выбрать процедуру таможенной очистки самостоятельно.",
    "HF.contact.title": "Ваше удовлетворение является нашим самым большим стремлением. Если у вас есть вопросы, предложения или намерения сотрудничать, пожалуйста, свяжитесь с нами в любое время. Мы готовы оказать Вам поддержку и помощь.",
    "HF.cacontact.card1.title": "Контактная информация",
    "HF.cacontact.card1.msg": "Больше для тебя, лучше для тебя.",
    "HF.Official.website": "Официальный сайт",
    "HF.link": "Нажмите фото на официальный сайт",
    "HF.about.subject": "Fomtoy упрощает импорт",
    "HF.about.msg": "Fomtoy - это интегрированная импортная платформа, которая предлагает вам десятки тысяч поставщиков, чтобы вы могли импортировать продукцию по более конкурентоспособным ценам. Мы также будем управлять всем процессом закупок, логистики и импорта для вас.",
    "HF.about.card1.title": "Самое мощное решение для электронной коммерции B2B в Китае",
    "HF.about.card1.content": "Fomtoy - это B2B - компания, основная цель которой - помочь компаниям импортировать продукцию из Китая наиболее экономичным способом, экономя затраты и упрощая процессы, чтобы предоставить клиентам наилучший опыт импорта. Наша штаб - квартира находится в Сучжоу, Китай, и имеет офисные помещения площадью более 4000 квадратных метров и склад площадью 15 000 квадратных метров. В настоящее время в компании работает 300 человек, и у нее есть отдельная команда по разработке технологий.",
    "HF.about.card1.label": "Спотовые продукты",
    "HF.about.card1.label2": "Патенты",
    "HF.about.card1.label3": "Новые продукты",
    "HF.about.card1.label4": "Страна",
    "HF.about.card2.title": "Наше преимущество",
    "HF.about.card2.msg": "Мы не только осмеливаемся обещать, но и можем это сделать.",
    "HF.about.card2.cell.tit": "Упрощение закупок и импорта из Китая, оптимизация всех затрат",
    "HF.about.card2.cell.msg": "Английский интерфейс позволяет легко завершить процесс покупки. Обеспечение контроля качества, складирования, международной логистики, таможенной очистки и других услуг. Английский язык для общения и послепродажного обслуживания.",
    "HF.about.card2.cell2.tit": "Оптимизация транспортных и импортных расходов",
    "HF.about.card2.cell2.tit2": "Весь процесс импорта из одного сайта",
    "HF.about.card2.cell2.tit3": "Хранение и комплексное управление импортом",
    "HF.about.card2.cell2.tit4": "Двуязычное обслуживание клиентов и послепродажное обслуживание",
    "HF.about.card2.cell3.tit": "Найдите все, что хотите, и фабрика мира в ваших руках.",
    "HF.about.card2.cell3.msg": "В настоящее время пользователи могут найти на Rakumart любой продукт от Alibaba, 1688 и других крупных отечественных платформ электронной коммерции в Китае. Наша обширная сеть сотрудничества позволяет нам предложить вам широкий ассортимент продукции и более 100 000 высококачественных китайских поставщиков.",
    "HF.about.card3.title": "У нас есть клиенты из разных компаний по всему миру, которые слушают, как они нас оценивают.",
    "HF.about.card3.msg": "Ищете наше место по всему миру?",
    "HF.about.card3.cell.tit": "Магия Фомтоя: за бесшовной обработкой заказов",
    "HF.about.card3.cell.msg": "До встречи с FulfillPanda меня беспокоил ежедневный объем заказов. Их бесшовная система обработки заказов действительно изменила правила игры, позволив мне сосредоточиться на своем бизнесе, не беспокоясь о запасах.",
    "HF.about.card3.cell2.tit": "3000 заказов в день: Мое быстрое экспансионистское путешествие",
    "HF.about.card3.cell2.msg": "Моя деловая поездка столкнулась с трудностями, но со временем, с помощью FulfillPanda, я теперь обрабатываю более 3000 заказов в день. Этот быстрый рост - это не просто количественный рост; Это свидетельствует о тяжелой работе нашей команды при поддержке Fomtoy.",
    "HF.about.card3.cell3.tit": "Превышение ожиданий: FulfillPanda предоставляет больше, чем просто услуги",
    "HF.about.card3.cell3.msg": "В нашем сотрудничестве с FulfillPanda я обнаружил, что они предлагают более одного вида услуг. Они внимательно следят за моим бизнесом и предлагают решения, которые превосходят ожидания традиционных поставщиков услуг. Это искреннее и страстное партнерство, которое выходит далеко за рамки бизнеса.",
    "HF.about.card3.cell4.tit": "Фульфильпанда - спаситель.",
    "HF.about.card3.cell4.msg": "Даже если у меня есть экспедитор, мне трудно масштабировать его пропорционально. Причины, по которым работают 17 обычных магазинов, различны, например, резервные копии магазинов и тестирование с использованием различных рекламных аккаунтов. Каждый день мы тестируем 50 продуктов, чтобы выявить победителей. Время от времени моей команде приходилось публиковать заявки на покупку на других платформах. Это занимает много времени. В то же время FulfillPanda будет внимательно следить за каждым вашим шагом, постоянно информировать вас о любой информации, связанной с проблемой запасов, и предлагать решения в любое время. Благодаря им нам приходится делать 3000 заказов в день. Большинство заказов доставляются вовремя. Я бы порекомендовал FulfillPanda!",
    "HF.about.card4.tit": "Миссия",
    "HF.about.card4.msg": "Наша миссия - предоставлять высококачественные продукты с отличной ценностью. Мы прислушиваемся к мнению наших клиентов и производителей. Мы смотрим на мир, в котором они живут, и предлагаем им новые тенденции в различных категориях товаров. Мы с энтузиазмом относимся к компаниям, которые мы представляем, а также к тенденциям и инновационным продуктам, которые они разрабатывают и создают. Мы искали невероятные идеи, и мы стремимся вывести эти замечательные новые продукты на каждый рынок.",
    "HF.about.card4.subtitle": "01.Наши ценности",
    "HF.about.card4.cell.tit": "Целевая ориентация",
    "HF.about.card4.cell.msg": "Мы по - прежнему сосредоточены на поддержании роста прибыли и расширении сферы дистрибуции для производителей.",
    "HF.about.card4.cell.tit2": "Честность",
    "HF.about.card4.cell.msg2": "Мы работаем с высокими стандартами добросовестности и этики во всем, что мы преследуем.",
    "HF.about.card4.cell.tit3": "Профессиональная этика",
    "HF.about.card4.cell.msg3": "Трудолюбие и настойчивость являются основой всех аспектов нашего бизнеса. В это сложное время мы очень стараемся.",
    "HF.about.card4.cell.tit4": "Внимание к деталям",
    "HF.about.card4.cell.msg4": "Мы понимаем, что маленькие проблемы требуют большего внимания, и тогда большие вещи позаботятся о себе.",
    "HF.about.card4.subtitle2": "02. Посещение",
    "HF.about.card4.subtitle2.msg": "Наша цель - стать лидером в предоставлении большего количества дистрибуции производителям, прислушиваясь к мнениям производителей, розничных торговцев и потребителей. Прибыль и структура в высокоорганизованной системе создадут успех. Мы стараемся мотивировать нашу клиентскую базу продуктами, о которых они не знают, и они никогда не знают, что им нужно или что они существуют. Однако, как только они начинают нравиться нашим продуктам, они не могут понять, что существует без них.",
    "HF.about.card5.Company": "Штаб - квартира компании",
    "HF.about.card5.info": "Сучжоу, Китай",
    "HF.about.card5.address": "Адрес компании",
    "HF.about.card5.addressName": "Промышленный парк Сучжоу, провинция Цзянсу, офисное здание 1818, 18 этаж",
    "HF.about.card5.Telephone": "Телефон",
    "HF.Hi": "Привет",
    "HF.companyProfile": "Краткая информация о компании",
    "HF.stainlessSteelProductFromHufa": 'Нержавеющая сталь из Шанхая',
    "HF.hufaFactoryWorkshop": 'Шанхайский заводской цех',
    "HF.showroom": 'Взаимный выставочный зал',
    "HF.hufaExhibition": 'Шанхайская выставка',
    "HF.customerWall": 'Стена клиента',
    "HF.shippingMoment": 'Время отгрузки',
    "HF.ourCertificate": 'Наши сертификаты',
    "HF.ourCertificate.text": "Наша сертификация качества является нашим обязательством. Каждая кухонная утварь в наших запасах тщательно изготовлена, чтобы соответствовать самым высоким стандартам безопасности и долговечности. Не волнуйся, я знаю, что твоя улыбка будет длиться вечно.",
    "HF.qq": "КQ",
    "HF.whatsapp":"WhatsApp",
    "HF.wechat": "Микросхема",
    "HF.outerBox": "Внешний ящик",
    "HF.innerBox": 'Внутренний ящик',
    "HF.moreBlogs": 'Больше блогов',
    "HF.corporateName": 'Завод нержавеющей стали',
    "HF.totalQuantity": "Всего",
    "HF.showroom.title": "Выставочный зал завода",
    "HF.showroom.item1": "Большой выставочный зал 600 м², в котором представлено более 3000 различных продуктов",
    "HF.showroom.item2": "Более 50 автоматизированных производственных машин",
    "HF.showroom.item3": "Производственная площадь завода составляет 9800м²",
    "HF.showroom.item4": "Обладает эксклюзивными отечественными и зарубежными патентными технологиями",
    "HF.showroom.item5": "Добро пожаловать в OEM и ODM",
    "HF.showroom.item6": "Адрес завода:",
    "HF.exhibition.title": "Выставочный зал Иу",
    "HF.exhibition.item1": "Три главных кухонных зала.",
    "HF.exhibition.item2": "Более 3000 гостиничных принадлежностей и бытовой техники",
    "HF.exhibition.item3": "Лучшая и самая большая команда в городе Иу.",
    "HF.exhibition.item4": "Готовые профессиональные предложения на более чем 50 различных типов кухонных принадлежностей",
    "HF.exhibition.item5": "Лучшие цены и самые эффективные услуги",
    "HF.exhibition.item6": "Адрес: Международный город Иу, 4 этаж, 22 двери, 19070.",
    "HF.contactUs.questions": "Мы стремимся предоставить вам персонализированные услуги. Если у вас есть вопросы, пожалуйста, свяжитесь с нами",
    "HF.contactUs.ideas": "Расскажите нам о своих идеях по проекту или просто поздоровайтесь. Независимо от того, есть ли у вас отличная идея или вам нужно вдохновение для некоторых архитектурных проектов, мы все здесь, чтобы создать идеальную архитектуру, от концепции до творчества, давайте вдохновим вас.",
    "HF.contactUs.work": "Мы готовы сотрудничать с вами.",
    "HF.contactUs.quotationAccordingly": "Пожалуйста, выберите товар, который вы хотите, и положите его в корзину для покупок, а затем свяжитесь с нами. Или вы можете оставить свое имя и электронную почту на странице входа, а затем мы можем отправить вам соответствующее предложение.",
    "HF.submitMessage": "Передача сообщений",
    "HF.QTY":"Количество"
}   
