/*
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-22 14:15:45
 * @LastEditors: xie
 * @LastEditTime: 2024-08-05 17:27:26
 */
import store from "../store/index"
import { setDocumentTitle } from '@/utils/domUtil'


/**
 * 设置当前模板
 */
export function templates() {
  // const template = "themeTemplateOne" // 新友好
  // const template = 'themeTemplateTow' // 小玩具
  // const template = 'themeTemplateThree' // 德科
  // const template = 'themeTemplateFour' // 凯荣丰
  // const template = "themeTemplateFive" // 锦泰国际
  // const template = "themeTemplateSix" // 国安
  // const template = 'themeTemplateSeven' // 南通拇指
  // const template = "themeTemplateEight" //多明戈
  // const template = "themeTemplateNine" // 正雅玩具
  const template = "themeTemplateTen" // 互发
  return template
}


/**
 * 传值需严格遵行下面的顺序
 * @param {Object} zhValue 中文
 * @param {Object} enValue 英文
 * @param {Object} threelanguages 第三语言
 */
export function filterLanguage(zhValue = '', enValue = '', threelanguages = '') {
  const i18n = store.state.operate.language
  let language = enValue
  switch (i18n) {
    case 'zh': // 中文
      language = zhValue
      setDocumentTitle(store.state.setting.webSetting.title)
      break;
    case 'en': // 英文
      language = enValue
      setDocumentTitle(store.state.setting.webSetting.enTitle)
      break;
    case 'ru': // 俄语
    case 'es': // 西班牙语
      language = threelanguages
      setDocumentTitle(store.state.setting.webSetting.rusTitle)
      break;
  }
  return language
}
