/*
 * @Description: 锦泰国际俄语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-05-23 15:12:33
 */
export default {
    "JT.navTitle.Home":"Главная  сайта",
    "JT.navTitle.Produch":"продукции Показать",
    "JT.navTitle.Cart":"Тележка покупок",
    "JT.navTitle.Exhibition":"План выставок",
    "JT.navTitle.Message":"Связаться / О нас",
    "JT.logoBox.placeholder":"Введите ключевые слова продук",
    "JT.ProductNumber":"Номер продукта",
    "JT.amount":"Количество",
    "JT.contactUs":"Свяжитесь с нами",
    "JT.understandDeke":"Понимание Деко",
    "JT.telephone":"Самолёт",
    "JT.Tel": "手机",
    "JT.phoneAndWeixin":"Мобильный микрофон",
    "JT.PleaseOrderNum":"Введите номер заказа.",
    "JT.ArticleNo":"Номер",
    "JT.TotalVolume":"Общий объем",
    "JT.totalQTY": "总数量",
    "JT.totalItems": "总款数",
    "JT.ExhibitionRelatedProducts":"Выставка ассоциированных продуктов",
    "JT.ProductsVideo":"Видео продукции",
    "JT.ReleaseDate":"Дата выпуска",
    "JT.inquirySheet":"Запрос",
    "JT.SendInquiry":"Запрос",
    "JT.productPrice":"Цены продукцию",
    "JT.OuterBoxCBM":"外箱材积",
    "JT.OuterBoxCUFT":"外箱体积",
    "JT.Innerboxes":"Количество ячеек",
    "JT.OuterCapacity":"Загрузка наружного ящика",
    "JT.productDetails":"Подробности продукции",
    "JT.similarProduct":"Похожие продукты",
    "JT.MyRFQ":"Мой запрос.",
    "JT.enquiry":"Запрос цены",
    "JT.enquirys": "请留下您的电子邮件，我们会与您联系",
    "JT.contact":"Контактная",
    "JT.Description":"Имя",
    "JT.OuterQTY":"外箱装量",
    "JT.LastSevenDays": "最近七天",
    "JT.All": "全部",
    "JT.PleaseEmial": "请输入邮箱",
    "JT.PleaseCode": "请输入验证码",
    "JT.PleasePhone": "请输入手机号",
    "JT.PleaseAccount": "请输入用户名",
    "JT.userName": "用户名",
    "JT.PleaseAddress": "请输入联系地址",
    "JT.PleasePassword": "请输入密码",
    "JT.PleaseName": "请输入姓名",
    "JT.CompanyProfile": "公司简介",
    "JT.ContactPerson": "联系人",
    "JT.Emial": "邮箱",
    "JT.enterSearch": "Please enter keywords",
    "JT.CompanyDescription": "21年专注儿童玩具企业",
    "JT.viewMore":"view more",
    "JT.Cart": "购物车",
    "JT.Service": "客服",
    "JT.WeChat": "微信",
    "JT.Top": "置顶",
    "JT.LastSevenDays": "Seven Days",
}   
