/*
 * @Description: 南通拇指西班牙语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-12-25 17:15:10
 */
export default {
    "NT.login":"Iniciar sesión",
    "NT.register":"Registro",
    "NT.logout":"Salida",
    "NT.MyOrder":"Mi pedido",
    "NT.logoBox.placeholder": "Palabras clave",
    "NT.logoBox.search": "Buscar",
    "NT.logoBox.AdvancedSearch": "Búsqueda avanzada",
    "NT.nav.Products": "Productos",
    "NT.nav.news": "Noticias",
    "NT.ViewPrice": "Ver el precio",
    "NT.SameFactory": "La misma fábrica",
    "NT.newPro": "Nuevos productos",
    "NT.AnotherBatch": "Cambiar un lote",
    "NT.ViewMore": "Ver más",
    "NT.hotPro": "Productos de venta caliente",
    "NT.hotVidio": "Video caliente",
    "NT.proUnBoxVidio": "Video de apertura del producto",
    "NT.Search": "Buscar",
    "NT.Reset": "Restablecer",
    "NT.ItemNO": "Número del proyecto",
    "NT.MTCode": "Número de la empresa",
    "NT.FTYCode": "Número de fábrica",
    "NT.Kind": "Tipo",
    "NT.Size": "Especificaciones",
    "NT.Price": "Precio",
    "NT.Date": "Fecha",
    "NT.Sort": "Ordenar",
    "NT.DateDesc": "Fecha Bajar",
    "NT.DateAsc": "Fecha Subir",
    "NT.PriceDesc": "Precio Bajar",
    "NT.PriceAsc": "Precio Subir",
    "NT.NO.Desc": "Número Bajar",
    "NT.NO.Asc": "Número Subir",
    "NT.PleaseEnterTheProductNameKeyword": "Introduzca la palabra clave del nombre del producto",
    "NT.PleaseSelect": "Por favor, elija",
    "NT.PleaseLength": "Largo",
    "NT.PleaseWidth": "Ancho",
    "NT.PleaseHeight": "Alto",
    "NT.PleaseMinPrice": "Precio mínimo",
    "NT.PleaseMaxPrice": "Precio máximo",
    "NT.PleaseStartDate": "Fecha de inicio",
    "NT.PleaseEndDate": "Fecha de finalización",
    "NT.UserAccount": "Cuenta",
    "NT.password": "Contraseña",
    "NT.GROUPSEARCH": "Búsqueda integral",
    "NT.Video_playback": "Reproducción de vídeo",
    "NT.loginMsg": "Acceso a su cuenta y historial de pedidos",
    "NT.onUserAccount": "No hay cuenta",
    "NT.SignUp": "Registro",
    "NT.ForgetPassword": "Olvida la contraseña",
    "NT.resetPasswords": "Restablecer la contraseña",
    "NT.emailAddress": "Buzón",
    "NT.verifyCode": "Código",
    "NT.getVerifyCode": "Acceso Código",
    "NT.newPassword": "Nueva contraseña",
    "NT.confirmPassword": "Confirmar contraseña",
    "NT.Submit": "Presentación",
    "NT.SignUpAccount": "Cuenta registrada",
    "NT.LoginOnly": "Solo para iniciar sesión",
    "NT.Name": "Nombre",
    "NT.NameMsg": "Introduzca su nombre, de 2 a 30 caracteres",
    "NT.passwordMsg": "Al menos 6 caracteres",
    "NT.requiredFields": "*obligatorio",
    "NT.Email": "Buzón",
    "NT.EmailMsg": "Por favor, introduzca el buzón. Para iniciar sesión.",
    "NT.gender": "Género",
    "NT.man": "Hombre",
    "NT.woman": "Señora",
    "NT.customerSource": "Fuente del cliente",
    "NT.salesman": "Vendedor",
    "NT.Country": "País",
    "NT.Company": "Empresa",
    "NT.duty": "Cargo",
    "NT.tel": "Teléfono",
    "NT.mobile": "Teléfono móvil",
    "NT.fax": "Fax",
    "NT.Yahoo": "Yahoo",
    "NT.website": "Sitio web",
    "NT.address": "Dirección",
    "NT.postalCode": "Código postal",
    "NT.ExistingAccount": "Cuenta existente",
    "NT.DirectLogin": "Iniciar sesión directamente",
    "NT.productPrice": "Precio del producto",
    "NT.ProductNumber": "Número de producto",
    "NT.Packing": "Método de embalaje",
    "NT.OuterBoxCBM": "Volumen de la caja exterior",
    "NT.OuterBoxCUFT": "Volumen de la caja exterior",
    "NT.Innerboxes": "Número de cajas interiores",
    "NT.OuterQTY": "Volumen de la caja exterior",
    "NT.GWAndNW": "Peso bruto / neto",
    "NT.productSize": "Especificaciones del producto",
    "NT.packageSize": "Especificaciones de embalaje",
    "NT.cartonSize": "Especificaciones de la caja exterior",
    "NT.addCart": "Añadir carrito de compras",
    "NT.delCart": "Fuera del carrito de la compra",
    "NT.product": "Productos",
    "NT.NewsUpdates": "Novedades periodísticas",
    "NT.ReleaseDate":"Fecha de publicación",
    "NT.MyCart":"Mi carrito de compras",
    "NT.ID":"ID",
    "NT.picture":"Imagen",
    "NT.ArticleNo": "Número",
    "NT.Description": "Nombre del producto",
    "NT.CartOuterQTY":"Fuera",
    "NT.CBM":"CBM",
    "NT.CTNS":"CTNS",
    "NT.totalQTY": "Total",
    "NT.TotalVolume":"Total CBM",
    "NT.total":"Total",
    "NT.delete":"Eliminar",
    "NT.checkAll":"Selección completa",
    "NT.clear":"Vaciado",
    "NT.totalItems": "Número total",
    "NT.totalCTNS": "Volumen total de cajas",
    "NT.noData": "No hay datos por el momento",
    "NT.totalPrice": "Precio total",
    "NT.systemTip": "Consejos del sistema",
    "NT.confirm": "Determinar",
    "NT.cancel": "Cancelación",
    "NT.deleteData": "¿¿ quieres eliminar este dato?",
    "NT.clearCartData": "¿¿ vaciar todos los carritos de la compra?",
    "NT.submitTip": "¿Confirmar la presentación del pedido?",
    "NT.MyOrder": "Mi pedido",
    "NT.PleaseOrderNum": "Por favor, introduzca el número de pedido.",
    "NT.number": "Número único",
    "NT.viewDetails": "Ver detalles",
    "NT.orderTime": "Tiempo de creación",
    "NT.linkman": "Contactos",
    "NT.sendDate": "Tiempo de envío",
    "NT.expressNumber": "Número de la lista de mensajería",
    "NT.orderStatus": "Estado del pedido",
    "NT.unshipped": "No enviado",
    "NT.shipped": "Enviado",
    "NT.SiteNavigation": "Navegación del sitio web",
    "NT.ContactUs": "Contáctanos",
    "NT.FollowUs": "Preste atención a nosotros",
    "NT.Landline": "Avión fijo",
    "NT.Email": "Buzón",
    "NT.Phone": "Móvil",
    "NT.WeChat": "WeChat",
    "NT.Technic": "Soporte técnico",
    "NT.Software": "HongSheng Soft",
    "NT.detailBtn": "Detalles del producto",
    "NT.noDetailBtn": "No Detalles del producto",
    "NT.classify": "Clasificación",
    "NT.exclusive": "Recomendaciones exclusivas para",
    "NT.loading": "Cargado...",
    "NT.updateTime": "Tiempo",
    "NT.SearchMsg": "Los criterios de búsqueda anteriores son opcionales para cualquiera o más búsquedas integrales.",
    "NT.MessageTitle": "Enviar sus necesidades en línea",
    "NT.MessageMsg": "Responderemos a su correo electrónico en 24 horas, excepto en días festivos. Esperamos escuchar su voz, ayudarlo a resolver problemas, responder dudas y explorar oportunidades de cooperación juntos. ¡¡ gracias por elegirnos y le serviremos de todo corazón!",
    "NT.MessageName": "Tu nombre",
    "NT.MessageTel": "Tu teléfono móvil",
    "NT.MessageEmail": "Tu buzón",
    "NT.MessageNeeds": "Tus necesidades",
    "NT.SendInquiry": "Investigación",
    "NT.EnquiryList": "Investigación",
    "NT.enquiry": "Investigación",
    "NT.MyEnquiryList": "Mi formulario de consulta",
    "NT.Remark": "Nota",
    "NT.Enquirys": "Deje su correo electrónico para facilitar el contacto.",
    "NT.proClass": "Clasificación de productos",
    "NT.submitOrder": "¿Confirmar la presentación de la consulta?",

    "NT.Co-branding": "Marca cooperativa",
    "NT.Co-brandingMsg": "Ya hay más de 100 empresas que cooperan en profundidad con nuestra empresa.",
    "NT.recommendPro": "Productos recomendados",
    "NT.Blog": "Blog",
    "NT.More": "Más",
    "NT.nav.Home": "Casa",
    "NT.nav.category": "Categoría",
    "NT.nav.Hot": "Venta caliente",
    "NT.nav.Gift": "Regalos",
    "NT.nav.New": "Nuevos productos",
    "NT.nav.video": "Vídeo",
    "NT.nav.Blog": "Blog",
    "NT.nav.About": "Sobre nosotros",
    "NT.nav.contact": "Contáctanos",
    "NT.nav.FooterNew": "Nuevos productos",
    "NT.news.title": "Todos los blogs",
    "NT.noMore": "No hay más",
    "NT.top.cart": "Carrito de compras",
    "NT.fomcat.title": "¿¿ por qué se eligió la marca fomcat?",
    "NT.fomcat.titleMsg": "¡¡ la ventaja está aquí! ¡Las ventajas de los mayoristas de juguetes fomcat no pueden ser ignoradas, ¡ veamos por qué somos sus mejores socios!",
    "NT.fomcat1.title1": "Una variedad de líneas de productos",
    "NT.fomcat1.Msg1": "Cubre juguetes para niños, juguetes de control remoto, modelos y colecciones, etc., para satisfacer las necesidades de diferentes clientes. ¡¡ no importa la edad a la que se enfrente su tienda, tenemos la opción perfecta!",
    "NT.fomcat2.title1": "Garantía de calidad del material",
    "NT.fomcat2.Msg1": "¡Seleccionamos materiales de alta calidad para crear juguetes seguros y confiables para los niños, sometidos a pruebas de mercado, ¡ para que los padres y los niños puedan disfrutar con tranquilidad!",
    "NT.fomcat3.title1": "Precios al por mayor competitivos",
    "NT.fomcat3.Msg1": "¡¡ ofrecemos precios al por mayor muy competitivos que le brindan un mayor margen de beneficio!",
    "NT.fomcat4.title1": "Cooperación fácil",
    "NT.fomcat4.Msg1": "Póngase en contacto con nosotros en cualquier momento y nuestro equipo resolverá sus problemas y necesidades de manera eficiente. ¡¡ nuestro objetivo es establecer una relación de cooperación exitosa a largo plazo!",
    "NT.fomcat5.title1": "Fácil pedido",
    "NT.fomcat5.title2": "Servicio íntimo",
    "NT.fomcat5.Mag1": "¡¡ su éxito es nuestro éxito! ¡¡ elige la marca fomcat y construye un hermoso parque de juguetes con nosotros!",
    "NT.userGuide": "Guía del usuario",
    "NT.internationalTransport": "Transporte Internacional",
    "NT.cardTitle1": "Sobre la tecnología de los sueños",
    "NT.cardTitle2": "Servicios logísticos y de transporte de Dream Technology",
    "NT.cardMsg": "Puede gestionar todo el proceso logístico de las mercancías importadas de China en su nombre; También puede cambiar completamente la forma en que compra e importa de china, ahorrando su tiempo de negocio y costos de compra.",
    "NT.card1Title": "Nuestra ventaja",
    "NT.card2.Title1": "Proceso logístico",
    "NT.card2.Title01": "Envío fuera de la fábrica",
    "NT.card2.Title02": "Envíos de China",
    "NT.card2.Title03": "Almacén de China",
    "NT.card2.Title04": "Aduana de China",
    "NT.card2.Title05": "Distribución internacional",
    "NT.card2.Title06": "Aduanas globales",
    "NT.card2.Title07": "Transporte nacional mundial",
    "NT.card3.Title": "Los servicios logísticos incluyen",
    "NT.card3.msg1": "Ofrece servicios logísticos completos desde la fábrica del proveedor hasta su dirección de recepción. Esto permite a nuestros clientes monitorear la ubicación de sus productos durante el proceso de importación en cualquier momento.",
    "NT.card3.msg2": "Tenemos más de 70.000 metros cuadrados de almacenes en china, donde recibimos, Procesamos y almacenamos mercancías hasta que se envían a todo el mundo.",
    "NT.card3.li": "Recibir mercancías de la fábrica",
    "NT.card3.li2": "Almacenamiento",
    "NT.card3.li3": "Embalaje de mercancías",
    "NT.card3.li4": "Gestión de la calidad",
    "NT.card3.li5": "Certificado de producción de productos",
    "NT.card3.li6": "Declaración de Aduanas",
    "NT.card3.li7": "Otros servicios",
    "NT.card4.title": "Información sobre transporte internacional y entrega internacional",
    "NT.card4.cell.title": "Gastos estimados",
    "NT.card4.cell.msg1": "Para ofrecer una oferta de transporte, necesitamos saber si los artículos incluidos en su pedido están sujetos a restricciones de importación y exportación, si la fábrica tiene inventario, tamaño, peso exacto del producto, etc.",
    "NT.card4.cell.msg2": "Por eso nuestro equipo necesita ponerse en contacto con los proveedores para conocer esta información y proporcionar estimaciones de costos.",
    "NT.card4.cell.msg3": "Recibirá estimaciones detalladas de los costos de transporte, el volumen cúbico y el tiempo de entrega de los tres modos de transporte disponibles (marítimo, aéreo y fedex).",
    "NT.card4.cell2.title": "Oferta final y transporte",
    "NT.card4.cell2.msg1": "Solo después de que las mercancías lleguen a nuestro almacén se puede obtener la mejor oferta de transporte. Por lo tanto, la cotización precisa del flete y otros gastos se calculan después de que recibimos la mercancía.",
    "NT.card4.cell2.msg2": "Una vez que los artículos lleguen a nuestro almacén, Agruparemos los productos para ofrecer el mejor precio.",
    "NT.card4.cell3.title": "Detalles del transporte de mercancías",
    "NT.card4.cell3.msg1": "Flete internacional.",
    "NT.card4.cell3.msg2": "Flete internacional enviado a todo el mundo.",
    "NT.card4.cell3.msg3": "Costos logísticos nacionales globales.",
    "NT.card4.cell3.msg4": "Después del despacho global de aduanas, organizaremos los envíos nacionales de acuerdo con la dirección de recepción.",
    "NT.card4.cell4.title": "Transporte de mercancías peligrosas",
    "NT.card4.cell4.msg1": "Las mercancías peligrosas son artículos con las siguientes características y atributos: amenazan la vida y la salud humanas; Causar daños irreparables al medio ambiente; Causar daños a objetos físicos.",
    "NT.card4.cell4.msg2": "Estos bienes incluyen: productos animales y animales, productos vegetales, grasas animales y vegetales, agentes alimentarios, metales comunes, instrumentos de precisión, armas, municiones y obras de arte.",

    "NT.card4.cell5.title": "Selección de compañías navieras internacionales",
    "NT.card4.cell5.msg1": "Se seleccionarán canales logísticos internacionales confiables, rápidos y económicos para sus mercancías. Después de una cuidadosa evaluación, hemos tenido en cuenta factores como los costos de transporte, la puntualidad del transporte y la estabilidad del servicio.",
    "NT.card4.cell5.msg2": "Trabajaremos constantemente para encontrar los mejores socios logísticos y rutas de transporte para servirle en nuestra plataforma.",

    "NT.card4.cell6.title": "Declaración de Aduanas",
    "NT.card4.cell6.msg1": "El despacho de aduanas se determinará en función del método de despacho de aduanas (marítimo, aéreo o terrestre) que elija al solicitar la entrega.",
    "NT.card4.cell6.msg2": "Nuestra empresa es responsable de manejar los asuntos de despacho de aduanas relacionados. Nuestra empresa será responsable de los asuntos de despacho de aduanas pertinentes. Los clientes también pueden optar por pasar por los trámites de despacho de aduanas por sí mismos.",
    "NT.contact.title": "Su satisfacción es nuestra mayor búsqueda. Si tiene alguna pregunta, sugerencia o intención de cooperar, no dude en contactarnos. Estamos dispuestos a brindarle apoyo y ayuda.",
    "NT.cacontact.card1.title": "Datos de contacto",
    "NT.cacontact.card1.msg": "Piensa más por ti y haz mejor por ti",
    "NT.Official.website": "Sitio web oficial",
    "NT.link": "Haga clic en la imagen y vaya a la página web oficial",
    "NT.about.subject": "Fomtoy facilita las importaciones",
    "NT.about.msg": "Fomtoy es una plataforma integrada de importación que le proporcionaremos decenas de miles de proveedores para que pueda importar productos a precios más competitivos. También gestionaremos todo el proceso de compra, logística e importación para usted.",
    "NT.about.card1.title": "La solución de comercio electrónico B2B más poderosa de China",
    "NT.about.card1.content": "Fomtoy es una empresa B2B cuyo objetivo principal es ayudar a las empresas a importar productos de China de la manera más rentable, ahorrar costos y simplificar los procesos, y proporcionar a los clientes la mejor experiencia de importación. Nuestra sede se encuentra en suzhou, china, con una superficie de Operaciones de oficina de más de 4.000 metros cuadrados y un almacén de 15.000 metros cuadrados. Tenemos 300 empleados y un equipo independiente de investigación y desarrollo tecnológico en la empresa del grupo.",
    "NT.about.card1.label": "Productos al contado",
    "NT.about.card1.label2": "Patentes",
    "NT.about.card1.label3": "Nuevos productos",
    "NT.about.card1.label4": "País",
    "NT.about.card2.title": "Nuestra ventaja",
    "NT.about.card2.msg": "No solo nos atrevemos a prometer, sino que también podemos hacerlo.",
    "NT.about.card2.cell.tit": "Simplifique sus compras e importaciones de China y optimice todos los costos",
    "NT.about.card2.cell.msg": "La interfaz en inglés le permite completar fácilmente el proceso de compra. Proporciona servicios de control de calidad, almacenamiento, logística internacional, despacho de aduanas y servicios adicionales. El Servicio al cliente en inglés facilita la comunicación y la posventa.",
    "NT.about.card2.cell2.tit": "Optimizar los costos de transporte e importación",
    "NT.about.card2.cell2.tit2": "Todo el proceso de importación desde un solo sitio",
    "NT.about.card2.cell2.tit3": "Almacenamiento y gestión integral de importaciones",
    "NT.about.card2.cell2.tit4": "Servicio al cliente bilingüe y servicio post - venta",
    "NT.about.card2.cell3.tit": "Encuentra todo lo que quieras, la fábrica del mundo está en tus manos.",
    "NT.about.card2.cell3.msg": "En la actualidad, los usuarios pueden encontrar cualquier producto de alibaba, 1688 y otras grandes plataformas nacionales de comercio electrónico en China en rakumart. Nuestra enorme red de cooperación nos permite ofrecerle una variedad de productos y más de 100.000 proveedores chinos de alta calidad.",
    "NT.about.card3.title": "Tenemos clientes de todo tipo de negocios en todo el mundo y escuchamos lo que nos valoran.",
    "NT.about.card3.msg": "¿¿ buscando nuestro lugar en todo el mundo?",
    "NT.about.card3.cell.tit": "La magia de fomtoy: la historia detrás de escena de manejar los pedidos sin problemas",
    "NT.about.card3.cell.msg": "Hasta que conocí a fulfillpanda, había estado preocupado por el volumen de pedidos diarios. Su sistema de procesamiento de pedidos sin fisuras realmente cambió las reglas del juego para que pudiera centrarme en mi negocio sin preocuparme por los problemas de inventario.",
    "NT.about.card3.cell2.tit": "3.000 pedidos diarios: mi viaje de rápida expansión",
    "NT.about.card3.cell2.msg": "Mi viaje de negocios se enfrentaba a desafíos, pero con el tiempo, con la ayuda de fulfillpanda, ahora estoy procesando más de 3.000 pedidos al día. Esta rápida expansión no es solo un crecimiento cuantitativo; Esto demuestra el arduo trabajo de nuestro equipo con el apoyo de fomtoy.",
    "NT.about.card3.cell3.tit": "Más allá de las expectativas: fulfillpanda ofrece más que servicios",
    "NT.about.card3.cell3.msg": "En mi colaboración con fulfillpanda, descubrí que ofrecen más que un servicio. Siguen de cerca mi negocio y ofrecen soluciones que superan las expectativas de los proveedores de servicios tradicionales. Se trata de una relación de cooperación sincera y entusiasta que va mucho más allá del ámbito empresarial.",
    "NT.about.card3.cell4.tit": "Fulfillpanda is a lifesaver...",
    "NT.about.card3.cell4.msg": "Even if I have a freight forwarder, it's difficult for me to scale proportionally. Due to running 17 regular stores for different reasons, such as store backup and testing with different advertising accounts. We test 50 products every day to find winners. My team has to occasionally post procurement requests to other platforms. This is very time-consuming. Meanwhile, FulfillPanda will closely monitor your every step, keeping you informed of any information related to inventory issues and providing solutions at any time. Thanks to them, we have to make 3000 orders every day. Most orders are shipped on time. I will definitely recommend FulfillPanda!",
    "NT.about.card4.tit": "Mission",
    "NT.about.card4.msg": "Nuestra misión es proporcionar productos de alta calidad con excelente valor. Escuchamos a nuestros clientes y fabricantes. Observamos el mundo en el que viven y les ofrecemos nuevas tendencias en múltiples categorías de bienes. Estamos entusiasmados con las empresas que representamos y las tendencias y productos innovadores que desarrollan y crean. Hemos estado buscando ideas increíbles y estamos ansiosos por llevar estos nuevos productos extraordinarios a cada mercado.",
    "NT.about.card4.subtitle": "01. nuestro valor",
    "NT.about.card4.cell.tit": "Orientación hacia los objetivos",
    "NT.about.card4.cell.msg": "Seguimos enfocados en mantener el crecimiento rentable y expandir el área de distribución para nuestros fabricantes.",
    "NT.about.card4.cell.tit2": "Integridad",
    "NT.about.card4.cell.msg2": "Operamos con integridad y altos estándares éticos y en todo lo que trabajamos.",
    "NT.about.card4.cell.tit3": "ética profesional",
    "NT.about.card4.cell.msg3": "La diligencia y la persistencia son fundamentales en todos los aspectos de nuestro negocio. Trabajamos muy duro en esta era desafiante.",
    "NT.about.card4.cell.tit4": "Concéntrese en los detalles",
    "NT.about.card4.cell.msg4": "Reconocemos que los pequeños problemas requieren más atención y luego las cosas más grandes se cuidan a sí mismas.",
    "NT.about.card4.subtitle2": "02. View",
    "NT.about.card4.subtitle2.msg": "Nuestro objetivo es ser líderes en proporcionar más distribución a los fabricantes escuchando a los fabricantes, minoristas y consumidores. Las ganancias y la estructura en el sistema de alta organización crearán éxito. Trabajamos para motivar a nuestra base de clientes con productos que no conocen, que nunca saben lo que necesitan o existen. Sin embargo, una vez que tienen una afición por nuestros productos, no pueden entender su existencia sin ellos.",
    "NT.about.card5.Company": "Sede de la empresa",
    "NT.about.card5.info": "China, Suzhou",
    "NT.about.card5.address": "Dirección de la empresa",
    "NT.about.card5.addressName": "Piso 18, edificio de oficinas 1818, parque industrial de suzhou, Provincia de Jiangsu",
    "NT.about.card5.Telephone": "Teléfono",





}   
