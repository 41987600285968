/*
 * @Description: 公司信息配置
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-12-29 17:23:23
 */
import { SET_SETTING, SET_MENUS, SET_LANGUAGE_CONFIG
 } from '../mutation-types'

const setting = {
    namespaced: true,
    state: {
        webSetting: {},
        menus: [],
        languageConfig: {}
    },
    mutations: {
        [SET_SETTING](state, data) {
            state.webSetting = data
        },
        [SET_MENUS](state, data) {
            state.menus = data
        },
        [SET_LANGUAGE_CONFIG](state, data) {
            state.languageConfig = data
        }
    },
    actions: {
        // 网站设置
        set_setting({ commit }, data) {
            commit(SET_SETTING, data)
        },
        // 设置菜单
        set_menus({ commit }, data) {
            commit(SET_MENUS, data)
        },
        // 站点语言
        set_language_config({ commit }, data) {
            commit(SET_LANGUAGE_CONFIG, data)
        }
    },
    getters: {
        get_setting: state => {
            return state.webSetting
        },
        get_menus: state => {
            return state.menus
        },
        get_language_config: state => {
            return state.languageConfig
        }
    }
}

export default setting